import { useQuery, UseQueryOptions } from '@tanstack/react-query'
export type Maybe<T> = T
export type InputMaybe<T> = T
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }

function fetcher<TData, TVariables>(endpoint: string, requestInit: RequestInit, query: string, variables?: TVariables) {
    return async (): Promise<TData> => {
        const res = await fetch(endpoint, {
            method: 'POST',
            ...requestInit,
            body: JSON.stringify({ query, variables }),
        })

        const json = await res.json()

        if (json.errors) {
            const { message } = json.errors[0]

            throw new Error(message)
        }

        return json.data
    }
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string }
    String: { input: string; output: string }
    Boolean: { input: boolean; output: boolean }
    Int: { input: number; output: number }
    Float: { input: number; output: number }
    BigDecimal: { input: any; output: any }
    BigInt: { input: any; output: any }
    Bytes: { input: any; output: any }
}

export type BlockChangedFilter = {
    number_gte: Scalars['Int']['input']
}

export type Block_Height = {
    hash?: InputMaybe<Scalars['Bytes']['input']>
    number?: InputMaybe<Scalars['Int']['input']>
    number_gte?: InputMaybe<Scalars['Int']['input']>
}

export type Bundle = {
    __typename?: 'Bundle'
    /** ETH price, in USD */
    ethPrice: Scalars['BigDecimal']['output']
    id: Scalars['ID']['output']
}

export type Bundle_Filter = {
    /** Filter for the block changed event. */
    _change_block?: InputMaybe<BlockChangedFilter>
    and?: InputMaybe<Array<InputMaybe<Bundle_Filter>>>
    ethPrice?: InputMaybe<Scalars['BigDecimal']['input']>
    ethPrice_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    ethPrice_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    ethPrice_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    ethPrice_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    ethPrice_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    ethPrice_not?: InputMaybe<Scalars['BigDecimal']['input']>
    ethPrice_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_gt?: InputMaybe<Scalars['ID']['input']>
    id_gte?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<Scalars['ID']['input']>>
    id_lt?: InputMaybe<Scalars['ID']['input']>
    id_lte?: InputMaybe<Scalars['ID']['input']>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>
    or?: InputMaybe<Array<InputMaybe<Bundle_Filter>>>
}

export enum Bundle_OrderBy {
    EthPrice = 'ethPrice',
    Id = 'id',
}

export type Burn = {
    __typename?: 'Burn'
    amount0?: Maybe<Scalars['BigDecimal']['output']>
    amount1?: Maybe<Scalars['BigDecimal']['output']>
    amountUSD?: Maybe<Scalars['BigDecimal']['output']>
    feeLiquidity?: Maybe<Scalars['BigDecimal']['output']>
    feeTo?: Maybe<Scalars['Bytes']['output']>
    id: Scalars['ID']['output']
    liquidity: Scalars['BigDecimal']['output']
    logIndex?: Maybe<Scalars['BigInt']['output']>
    needsComplete: Scalars['Boolean']['output']
    pair: Pair
    sender?: Maybe<Scalars['Bytes']['output']>
    timestamp: Scalars['BigInt']['output']
    to?: Maybe<Scalars['Bytes']['output']>
    transaction: Transaction
}

export type Burn_Filter = {
    /** Filter for the block changed event. */
    _change_block?: InputMaybe<BlockChangedFilter>
    amount0?: InputMaybe<Scalars['BigDecimal']['input']>
    amount0_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    amount0_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    amount0_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    amount0_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    amount0_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    amount0_not?: InputMaybe<Scalars['BigDecimal']['input']>
    amount0_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    amount1?: InputMaybe<Scalars['BigDecimal']['input']>
    amount1_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    amount1_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    amount1_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    amount1_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    amount1_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    amount1_not?: InputMaybe<Scalars['BigDecimal']['input']>
    amount1_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    amountUSD?: InputMaybe<Scalars['BigDecimal']['input']>
    amountUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    amountUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    amountUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    amountUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    amountUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    amountUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>
    amountUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    and?: InputMaybe<Array<InputMaybe<Burn_Filter>>>
    feeLiquidity?: InputMaybe<Scalars['BigDecimal']['input']>
    feeLiquidity_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    feeLiquidity_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    feeLiquidity_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    feeLiquidity_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    feeLiquidity_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    feeLiquidity_not?: InputMaybe<Scalars['BigDecimal']['input']>
    feeLiquidity_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    feeTo?: InputMaybe<Scalars['Bytes']['input']>
    feeTo_contains?: InputMaybe<Scalars['Bytes']['input']>
    feeTo_gt?: InputMaybe<Scalars['Bytes']['input']>
    feeTo_gte?: InputMaybe<Scalars['Bytes']['input']>
    feeTo_in?: InputMaybe<Array<Scalars['Bytes']['input']>>
    feeTo_lt?: InputMaybe<Scalars['Bytes']['input']>
    feeTo_lte?: InputMaybe<Scalars['Bytes']['input']>
    feeTo_not?: InputMaybe<Scalars['Bytes']['input']>
    feeTo_not_contains?: InputMaybe<Scalars['Bytes']['input']>
    feeTo_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_gt?: InputMaybe<Scalars['ID']['input']>
    id_gte?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<Scalars['ID']['input']>>
    id_lt?: InputMaybe<Scalars['ID']['input']>
    id_lte?: InputMaybe<Scalars['ID']['input']>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>
    liquidity?: InputMaybe<Scalars['BigDecimal']['input']>
    liquidity_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    liquidity_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    liquidity_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    liquidity_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    liquidity_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    liquidity_not?: InputMaybe<Scalars['BigDecimal']['input']>
    liquidity_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    logIndex?: InputMaybe<Scalars['BigInt']['input']>
    logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>
    logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>
    logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>
    logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>
    logIndex_not?: InputMaybe<Scalars['BigInt']['input']>
    logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    needsComplete?: InputMaybe<Scalars['Boolean']['input']>
    needsComplete_in?: InputMaybe<Array<Scalars['Boolean']['input']>>
    needsComplete_not?: InputMaybe<Scalars['Boolean']['input']>
    needsComplete_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>
    or?: InputMaybe<Array<InputMaybe<Burn_Filter>>>
    pair?: InputMaybe<Scalars['String']['input']>
    pair_?: InputMaybe<Pair_Filter>
    pair_contains?: InputMaybe<Scalars['String']['input']>
    pair_contains_nocase?: InputMaybe<Scalars['String']['input']>
    pair_ends_with?: InputMaybe<Scalars['String']['input']>
    pair_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    pair_gt?: InputMaybe<Scalars['String']['input']>
    pair_gte?: InputMaybe<Scalars['String']['input']>
    pair_in?: InputMaybe<Array<Scalars['String']['input']>>
    pair_lt?: InputMaybe<Scalars['String']['input']>
    pair_lte?: InputMaybe<Scalars['String']['input']>
    pair_not?: InputMaybe<Scalars['String']['input']>
    pair_not_contains?: InputMaybe<Scalars['String']['input']>
    pair_not_contains_nocase?: InputMaybe<Scalars['String']['input']>
    pair_not_ends_with?: InputMaybe<Scalars['String']['input']>
    pair_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    pair_not_in?: InputMaybe<Array<Scalars['String']['input']>>
    pair_not_starts_with?: InputMaybe<Scalars['String']['input']>
    pair_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    pair_starts_with?: InputMaybe<Scalars['String']['input']>
    pair_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['Bytes']['input']>
    sender_contains?: InputMaybe<Scalars['Bytes']['input']>
    sender_gt?: InputMaybe<Scalars['Bytes']['input']>
    sender_gte?: InputMaybe<Scalars['Bytes']['input']>
    sender_in?: InputMaybe<Array<Scalars['Bytes']['input']>>
    sender_lt?: InputMaybe<Scalars['Bytes']['input']>
    sender_lte?: InputMaybe<Scalars['Bytes']['input']>
    sender_not?: InputMaybe<Scalars['Bytes']['input']>
    sender_not_contains?: InputMaybe<Scalars['Bytes']['input']>
    sender_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>
    timestamp?: InputMaybe<Scalars['BigInt']['input']>
    timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>
    timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>
    timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>
    timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>
    timestamp_not?: InputMaybe<Scalars['BigInt']['input']>
    timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    to?: InputMaybe<Scalars['Bytes']['input']>
    to_contains?: InputMaybe<Scalars['Bytes']['input']>
    to_gt?: InputMaybe<Scalars['Bytes']['input']>
    to_gte?: InputMaybe<Scalars['Bytes']['input']>
    to_in?: InputMaybe<Array<Scalars['Bytes']['input']>>
    to_lt?: InputMaybe<Scalars['Bytes']['input']>
    to_lte?: InputMaybe<Scalars['Bytes']['input']>
    to_not?: InputMaybe<Scalars['Bytes']['input']>
    to_not_contains?: InputMaybe<Scalars['Bytes']['input']>
    to_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>
    transaction?: InputMaybe<Scalars['String']['input']>
    transaction_?: InputMaybe<Transaction_Filter>
    transaction_contains?: InputMaybe<Scalars['String']['input']>
    transaction_contains_nocase?: InputMaybe<Scalars['String']['input']>
    transaction_ends_with?: InputMaybe<Scalars['String']['input']>
    transaction_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    transaction_gt?: InputMaybe<Scalars['String']['input']>
    transaction_gte?: InputMaybe<Scalars['String']['input']>
    transaction_in?: InputMaybe<Array<Scalars['String']['input']>>
    transaction_lt?: InputMaybe<Scalars['String']['input']>
    transaction_lte?: InputMaybe<Scalars['String']['input']>
    transaction_not?: InputMaybe<Scalars['String']['input']>
    transaction_not_contains?: InputMaybe<Scalars['String']['input']>
    transaction_not_contains_nocase?: InputMaybe<Scalars['String']['input']>
    transaction_not_ends_with?: InputMaybe<Scalars['String']['input']>
    transaction_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    transaction_not_in?: InputMaybe<Array<Scalars['String']['input']>>
    transaction_not_starts_with?: InputMaybe<Scalars['String']['input']>
    transaction_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    transaction_starts_with?: InputMaybe<Scalars['String']['input']>
    transaction_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
}

export enum Burn_OrderBy {
    Amount0 = 'amount0',
    Amount1 = 'amount1',
    AmountUsd = 'amountUSD',
    FeeLiquidity = 'feeLiquidity',
    FeeTo = 'feeTo',
    Id = 'id',
    Liquidity = 'liquidity',
    LogIndex = 'logIndex',
    NeedsComplete = 'needsComplete',
    Pair = 'pair',
    PairBlock = 'pair__block',
    PairCurrentFee = 'pair__currentFee',
    PairId = 'pair__id',
    PairLpTokenPrice = 'pair__lpTokenPrice',
    PairName = 'pair__name',
    PairReserve0 = 'pair__reserve0',
    PairReserve1 = 'pair__reserve1',
    PairReserveEth = 'pair__reserveETH',
    PairReserveUsd = 'pair__reserveUSD',
    PairTimestamp = 'pair__timestamp',
    PairToken0Price = 'pair__token0Price',
    PairToken1Price = 'pair__token1Price',
    PairTotalFee = 'pair__totalFee',
    PairTotalSupply = 'pair__totalSupply',
    PairTotalTransactions = 'pair__totalTransactions',
    PairTrackedReserveEth = 'pair__trackedReserveETH',
    PairUntrackedVolumeUsd = 'pair__untrackedVolumeUSD',
    PairVolumeToken0 = 'pair__volumeToken0',
    PairVolumeToken1 = 'pair__volumeToken1',
    PairVolumeUsd = 'pair__volumeUSD',
    Sender = 'sender',
    Timestamp = 'timestamp',
    To = 'to',
    Transaction = 'transaction',
    TransactionBlock = 'transaction__block',
    TransactionId = 'transaction__id',
    TransactionTimestamp = 'transaction__timestamp',
}

export type EchodexDayData = {
    __typename?: 'EchodexDayData'
    dailyVolumeETH: Scalars['BigDecimal']['output']
    dailyVolumeUSD: Scalars['BigDecimal']['output']
    dailyVolumeUntracked: Scalars['BigDecimal']['output']
    date: Scalars['Int']['output']
    id: Scalars['ID']['output']
    totalLiquidityETH: Scalars['BigDecimal']['output']
    totalLiquidityUSD: Scalars['BigDecimal']['output']
    totalTransactions: Scalars['BigInt']['output']
    totalVolumeETH: Scalars['BigDecimal']['output']
    totalVolumeUSD: Scalars['BigDecimal']['output']
}

export type EchodexDayData_Filter = {
    /** Filter for the block changed event. */
    _change_block?: InputMaybe<BlockChangedFilter>
    and?: InputMaybe<Array<InputMaybe<EchodexDayData_Filter>>>
    dailyVolumeETH?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeETH_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeETH_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeETH_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    dailyVolumeETH_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeETH_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeETH_not?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeETH_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    dailyVolumeUSD?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    dailyVolumeUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    dailyVolumeUntracked?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeUntracked_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeUntracked_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeUntracked_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    dailyVolumeUntracked_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeUntracked_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeUntracked_not?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeUntracked_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    date?: InputMaybe<Scalars['Int']['input']>
    date_gt?: InputMaybe<Scalars['Int']['input']>
    date_gte?: InputMaybe<Scalars['Int']['input']>
    date_in?: InputMaybe<Array<Scalars['Int']['input']>>
    date_lt?: InputMaybe<Scalars['Int']['input']>
    date_lte?: InputMaybe<Scalars['Int']['input']>
    date_not?: InputMaybe<Scalars['Int']['input']>
    date_not_in?: InputMaybe<Array<Scalars['Int']['input']>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_gt?: InputMaybe<Scalars['ID']['input']>
    id_gte?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<Scalars['ID']['input']>>
    id_lt?: InputMaybe<Scalars['ID']['input']>
    id_lte?: InputMaybe<Scalars['ID']['input']>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>
    or?: InputMaybe<Array<InputMaybe<EchodexDayData_Filter>>>
    totalLiquidityETH?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityETH_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityETH_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityETH_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    totalLiquidityETH_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityETH_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityETH_not?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityETH_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    totalLiquidityUSD?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    totalLiquidityUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    totalTransactions?: InputMaybe<Scalars['BigInt']['input']>
    totalTransactions_gt?: InputMaybe<Scalars['BigInt']['input']>
    totalTransactions_gte?: InputMaybe<Scalars['BigInt']['input']>
    totalTransactions_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    totalTransactions_lt?: InputMaybe<Scalars['BigInt']['input']>
    totalTransactions_lte?: InputMaybe<Scalars['BigInt']['input']>
    totalTransactions_not?: InputMaybe<Scalars['BigInt']['input']>
    totalTransactions_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    totalVolumeETH?: InputMaybe<Scalars['BigDecimal']['input']>
    totalVolumeETH_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    totalVolumeETH_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    totalVolumeETH_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    totalVolumeETH_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    totalVolumeETH_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    totalVolumeETH_not?: InputMaybe<Scalars['BigDecimal']['input']>
    totalVolumeETH_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    totalVolumeUSD?: InputMaybe<Scalars['BigDecimal']['input']>
    totalVolumeUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    totalVolumeUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    totalVolumeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    totalVolumeUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    totalVolumeUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    totalVolumeUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>
    totalVolumeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
}

export enum EchodexDayData_OrderBy {
    DailyVolumeEth = 'dailyVolumeETH',
    DailyVolumeUsd = 'dailyVolumeUSD',
    DailyVolumeUntracked = 'dailyVolumeUntracked',
    Date = 'date',
    Id = 'id',
    TotalLiquidityEth = 'totalLiquidityETH',
    TotalLiquidityUsd = 'totalLiquidityUSD',
    TotalTransactions = 'totalTransactions',
    TotalVolumeEth = 'totalVolumeETH',
    TotalVolumeUsd = 'totalVolumeUSD',
}

export type EchodexFactory = {
    __typename?: 'EchodexFactory'
    id: Scalars['ID']['output']
    totalLiquidityETH: Scalars['BigDecimal']['output']
    totalLiquidityUSD: Scalars['BigDecimal']['output']
    /** Total of pairs */
    totalPairs: Scalars['BigInt']['output']
    /** Total of transactions */
    totalTransactions: Scalars['BigInt']['output']
    totalVolumeETH: Scalars['BigDecimal']['output']
    totalVolumeUSD: Scalars['BigDecimal']['output']
    untrackedVolumeUSD: Scalars['BigDecimal']['output']
}

export type EchodexFactory_Filter = {
    /** Filter for the block changed event. */
    _change_block?: InputMaybe<BlockChangedFilter>
    and?: InputMaybe<Array<InputMaybe<EchodexFactory_Filter>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_gt?: InputMaybe<Scalars['ID']['input']>
    id_gte?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<Scalars['ID']['input']>>
    id_lt?: InputMaybe<Scalars['ID']['input']>
    id_lte?: InputMaybe<Scalars['ID']['input']>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>
    or?: InputMaybe<Array<InputMaybe<EchodexFactory_Filter>>>
    totalLiquidityETH?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityETH_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityETH_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityETH_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    totalLiquidityETH_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityETH_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityETH_not?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityETH_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    totalLiquidityUSD?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    totalLiquidityUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    totalPairs?: InputMaybe<Scalars['BigInt']['input']>
    totalPairs_gt?: InputMaybe<Scalars['BigInt']['input']>
    totalPairs_gte?: InputMaybe<Scalars['BigInt']['input']>
    totalPairs_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    totalPairs_lt?: InputMaybe<Scalars['BigInt']['input']>
    totalPairs_lte?: InputMaybe<Scalars['BigInt']['input']>
    totalPairs_not?: InputMaybe<Scalars['BigInt']['input']>
    totalPairs_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    totalTransactions?: InputMaybe<Scalars['BigInt']['input']>
    totalTransactions_gt?: InputMaybe<Scalars['BigInt']['input']>
    totalTransactions_gte?: InputMaybe<Scalars['BigInt']['input']>
    totalTransactions_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    totalTransactions_lt?: InputMaybe<Scalars['BigInt']['input']>
    totalTransactions_lte?: InputMaybe<Scalars['BigInt']['input']>
    totalTransactions_not?: InputMaybe<Scalars['BigInt']['input']>
    totalTransactions_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    totalVolumeETH?: InputMaybe<Scalars['BigDecimal']['input']>
    totalVolumeETH_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    totalVolumeETH_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    totalVolumeETH_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    totalVolumeETH_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    totalVolumeETH_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    totalVolumeETH_not?: InputMaybe<Scalars['BigDecimal']['input']>
    totalVolumeETH_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    totalVolumeUSD?: InputMaybe<Scalars['BigDecimal']['input']>
    totalVolumeUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    totalVolumeUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    totalVolumeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    totalVolumeUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    totalVolumeUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    totalVolumeUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>
    totalVolumeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    untrackedVolumeUSD?: InputMaybe<Scalars['BigDecimal']['input']>
    untrackedVolumeUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    untrackedVolumeUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    untrackedVolumeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    untrackedVolumeUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    untrackedVolumeUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    untrackedVolumeUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>
    untrackedVolumeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
}

export enum EchodexFactory_OrderBy {
    Id = 'id',
    TotalLiquidityEth = 'totalLiquidityETH',
    TotalLiquidityUsd = 'totalLiquidityUSD',
    TotalPairs = 'totalPairs',
    TotalTransactions = 'totalTransactions',
    TotalVolumeEth = 'totalVolumeETH',
    TotalVolumeUsd = 'totalVolumeUSD',
    UntrackedVolumeUsd = 'untrackedVolumeUSD',
}

export type FarmingPool = {
    __typename?: 'FarmingPool'
    accAmountPerShare?: Maybe<Scalars['BigDecimal']['output']>
    amountPerSecond: Scalars['BigDecimal']['output']
    amountPerSecondUSD?: Maybe<Scalars['BigDecimal']['output']>
    amountReward: Scalars['BigDecimal']['output']
    apr: Scalars['BigDecimal']['output']
    endDate: Scalars['BigDecimal']['output']
    farmingUsers: Array<FarmingUser>
    harvested: Scalars['BigDecimal']['output']
    id: Scalars['ID']['output']
    isBlueCheck?: Maybe<Scalars['Boolean']['output']>
    lastRewardTimestamp?: Maybe<Scalars['Int']['output']>
    owner: Scalars['String']['output']
    pair: Pair
    sortIndex: Scalars['BigDecimal']['output']
    startDate: Scalars['BigDecimal']['output']
    tokenReward: Token
    totalLP: Scalars['BigDecimal']['output']
    totalLpUsd?: Maybe<Scalars['BigDecimal']['output']>
    totalReward?: Maybe<Scalars['BigDecimal']['output']>
    withdraw?: Maybe<Scalars['Boolean']['output']>
}

export type FarmingPoolFarmingUsersArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<FarmingUser_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    where?: InputMaybe<FarmingUser_Filter>
}

export type FarmingPool_Filter = {
    /** Filter for the block changed event. */
    _change_block?: InputMaybe<BlockChangedFilter>
    accAmountPerShare?: InputMaybe<Scalars['BigDecimal']['input']>
    accAmountPerShare_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    accAmountPerShare_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    accAmountPerShare_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    accAmountPerShare_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    accAmountPerShare_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    accAmountPerShare_not?: InputMaybe<Scalars['BigDecimal']['input']>
    accAmountPerShare_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    amountPerSecond?: InputMaybe<Scalars['BigDecimal']['input']>
    amountPerSecondUSD?: InputMaybe<Scalars['BigDecimal']['input']>
    amountPerSecondUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    amountPerSecondUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    amountPerSecondUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    amountPerSecondUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    amountPerSecondUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    amountPerSecondUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>
    amountPerSecondUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    amountPerSecond_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    amountPerSecond_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    amountPerSecond_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    amountPerSecond_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    amountPerSecond_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    amountPerSecond_not?: InputMaybe<Scalars['BigDecimal']['input']>
    amountPerSecond_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    amountReward?: InputMaybe<Scalars['BigDecimal']['input']>
    amountReward_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    amountReward_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    amountReward_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    amountReward_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    amountReward_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    amountReward_not?: InputMaybe<Scalars['BigDecimal']['input']>
    amountReward_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    and?: InputMaybe<Array<InputMaybe<FarmingPool_Filter>>>
    apr?: InputMaybe<Scalars['BigDecimal']['input']>
    apr_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    apr_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    apr_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    apr_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    apr_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    apr_not?: InputMaybe<Scalars['BigDecimal']['input']>
    apr_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    endDate?: InputMaybe<Scalars['BigDecimal']['input']>
    endDate_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    endDate_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    endDate_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    endDate_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    endDate_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    endDate_not?: InputMaybe<Scalars['BigDecimal']['input']>
    endDate_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    farmingUsers_?: InputMaybe<FarmingUser_Filter>
    harvested?: InputMaybe<Scalars['BigDecimal']['input']>
    harvested_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    harvested_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    harvested_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    harvested_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    harvested_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    harvested_not?: InputMaybe<Scalars['BigDecimal']['input']>
    harvested_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_gt?: InputMaybe<Scalars['ID']['input']>
    id_gte?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<Scalars['ID']['input']>>
    id_lt?: InputMaybe<Scalars['ID']['input']>
    id_lte?: InputMaybe<Scalars['ID']['input']>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>
    isBlueCheck?: InputMaybe<Scalars['Boolean']['input']>
    isBlueCheck_in?: InputMaybe<Array<Scalars['Boolean']['input']>>
    isBlueCheck_not?: InputMaybe<Scalars['Boolean']['input']>
    isBlueCheck_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>
    lastRewardTimestamp?: InputMaybe<Scalars['Int']['input']>
    lastRewardTimestamp_gt?: InputMaybe<Scalars['Int']['input']>
    lastRewardTimestamp_gte?: InputMaybe<Scalars['Int']['input']>
    lastRewardTimestamp_in?: InputMaybe<Array<Scalars['Int']['input']>>
    lastRewardTimestamp_lt?: InputMaybe<Scalars['Int']['input']>
    lastRewardTimestamp_lte?: InputMaybe<Scalars['Int']['input']>
    lastRewardTimestamp_not?: InputMaybe<Scalars['Int']['input']>
    lastRewardTimestamp_not_in?: InputMaybe<Array<Scalars['Int']['input']>>
    or?: InputMaybe<Array<InputMaybe<FarmingPool_Filter>>>
    owner?: InputMaybe<Scalars['String']['input']>
    owner_contains?: InputMaybe<Scalars['String']['input']>
    owner_contains_nocase?: InputMaybe<Scalars['String']['input']>
    owner_ends_with?: InputMaybe<Scalars['String']['input']>
    owner_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    owner_gt?: InputMaybe<Scalars['String']['input']>
    owner_gte?: InputMaybe<Scalars['String']['input']>
    owner_in?: InputMaybe<Array<Scalars['String']['input']>>
    owner_lt?: InputMaybe<Scalars['String']['input']>
    owner_lte?: InputMaybe<Scalars['String']['input']>
    owner_not?: InputMaybe<Scalars['String']['input']>
    owner_not_contains?: InputMaybe<Scalars['String']['input']>
    owner_not_contains_nocase?: InputMaybe<Scalars['String']['input']>
    owner_not_ends_with?: InputMaybe<Scalars['String']['input']>
    owner_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    owner_not_in?: InputMaybe<Array<Scalars['String']['input']>>
    owner_not_starts_with?: InputMaybe<Scalars['String']['input']>
    owner_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    owner_starts_with?: InputMaybe<Scalars['String']['input']>
    owner_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    pair?: InputMaybe<Scalars['String']['input']>
    pair_?: InputMaybe<Pair_Filter>
    pair_contains?: InputMaybe<Scalars['String']['input']>
    pair_contains_nocase?: InputMaybe<Scalars['String']['input']>
    pair_ends_with?: InputMaybe<Scalars['String']['input']>
    pair_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    pair_gt?: InputMaybe<Scalars['String']['input']>
    pair_gte?: InputMaybe<Scalars['String']['input']>
    pair_in?: InputMaybe<Array<Scalars['String']['input']>>
    pair_lt?: InputMaybe<Scalars['String']['input']>
    pair_lte?: InputMaybe<Scalars['String']['input']>
    pair_not?: InputMaybe<Scalars['String']['input']>
    pair_not_contains?: InputMaybe<Scalars['String']['input']>
    pair_not_contains_nocase?: InputMaybe<Scalars['String']['input']>
    pair_not_ends_with?: InputMaybe<Scalars['String']['input']>
    pair_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    pair_not_in?: InputMaybe<Array<Scalars['String']['input']>>
    pair_not_starts_with?: InputMaybe<Scalars['String']['input']>
    pair_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    pair_starts_with?: InputMaybe<Scalars['String']['input']>
    pair_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    sortIndex?: InputMaybe<Scalars['BigDecimal']['input']>
    sortIndex_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    sortIndex_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    sortIndex_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    sortIndex_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    sortIndex_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    sortIndex_not?: InputMaybe<Scalars['BigDecimal']['input']>
    sortIndex_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    startDate?: InputMaybe<Scalars['BigDecimal']['input']>
    startDate_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    startDate_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    startDate_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    startDate_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    startDate_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    startDate_not?: InputMaybe<Scalars['BigDecimal']['input']>
    startDate_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    tokenReward?: InputMaybe<Scalars['String']['input']>
    tokenReward_?: InputMaybe<Token_Filter>
    tokenReward_contains?: InputMaybe<Scalars['String']['input']>
    tokenReward_contains_nocase?: InputMaybe<Scalars['String']['input']>
    tokenReward_ends_with?: InputMaybe<Scalars['String']['input']>
    tokenReward_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    tokenReward_gt?: InputMaybe<Scalars['String']['input']>
    tokenReward_gte?: InputMaybe<Scalars['String']['input']>
    tokenReward_in?: InputMaybe<Array<Scalars['String']['input']>>
    tokenReward_lt?: InputMaybe<Scalars['String']['input']>
    tokenReward_lte?: InputMaybe<Scalars['String']['input']>
    tokenReward_not?: InputMaybe<Scalars['String']['input']>
    tokenReward_not_contains?: InputMaybe<Scalars['String']['input']>
    tokenReward_not_contains_nocase?: InputMaybe<Scalars['String']['input']>
    tokenReward_not_ends_with?: InputMaybe<Scalars['String']['input']>
    tokenReward_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    tokenReward_not_in?: InputMaybe<Array<Scalars['String']['input']>>
    tokenReward_not_starts_with?: InputMaybe<Scalars['String']['input']>
    tokenReward_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    tokenReward_starts_with?: InputMaybe<Scalars['String']['input']>
    tokenReward_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    totalLP?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLP_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLP_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLP_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    totalLP_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLP_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLP_not?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLP_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    totalLpUsd?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLpUsd_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLpUsd_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLpUsd_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    totalLpUsd_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLpUsd_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLpUsd_not?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLpUsd_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    totalReward?: InputMaybe<Scalars['BigDecimal']['input']>
    totalReward_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    totalReward_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    totalReward_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    totalReward_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    totalReward_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    totalReward_not?: InputMaybe<Scalars['BigDecimal']['input']>
    totalReward_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    withdraw?: InputMaybe<Scalars['Boolean']['input']>
    withdraw_in?: InputMaybe<Array<Scalars['Boolean']['input']>>
    withdraw_not?: InputMaybe<Scalars['Boolean']['input']>
    withdraw_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>
}

export enum FarmingPool_OrderBy {
    AccAmountPerShare = 'accAmountPerShare',
    AmountPerSecond = 'amountPerSecond',
    AmountPerSecondUsd = 'amountPerSecondUSD',
    AmountReward = 'amountReward',
    Apr = 'apr',
    EndDate = 'endDate',
    FarmingUsers = 'farmingUsers',
    Harvested = 'harvested',
    Id = 'id',
    IsBlueCheck = 'isBlueCheck',
    LastRewardTimestamp = 'lastRewardTimestamp',
    Owner = 'owner',
    Pair = 'pair',
    PairBlock = 'pair__block',
    PairCurrentFee = 'pair__currentFee',
    PairId = 'pair__id',
    PairLpTokenPrice = 'pair__lpTokenPrice',
    PairName = 'pair__name',
    PairReserve0 = 'pair__reserve0',
    PairReserve1 = 'pair__reserve1',
    PairReserveEth = 'pair__reserveETH',
    PairReserveUsd = 'pair__reserveUSD',
    PairTimestamp = 'pair__timestamp',
    PairToken0Price = 'pair__token0Price',
    PairToken1Price = 'pair__token1Price',
    PairTotalFee = 'pair__totalFee',
    PairTotalSupply = 'pair__totalSupply',
    PairTotalTransactions = 'pair__totalTransactions',
    PairTrackedReserveEth = 'pair__trackedReserveETH',
    PairUntrackedVolumeUsd = 'pair__untrackedVolumeUSD',
    PairVolumeToken0 = 'pair__volumeToken0',
    PairVolumeToken1 = 'pair__volumeToken1',
    PairVolumeUsd = 'pair__volumeUSD',
    SortIndex = 'sortIndex',
    StartDate = 'startDate',
    TokenReward = 'tokenReward',
    TokenRewardDecimals = 'tokenReward__decimals',
    TokenRewardDerivedEth = 'tokenReward__derivedETH',
    TokenRewardDerivedUsd = 'tokenReward__derivedUSD',
    TokenRewardId = 'tokenReward__id',
    TokenRewardName = 'tokenReward__name',
    TokenRewardSymbol = 'tokenReward__symbol',
    TokenRewardTotalLiquidity = 'tokenReward__totalLiquidity',
    TokenRewardTotalTransactions = 'tokenReward__totalTransactions',
    TokenRewardTradeVolume = 'tokenReward__tradeVolume',
    TokenRewardTradeVolumeUsd = 'tokenReward__tradeVolumeUSD',
    TokenRewardUntrackedVolumeUsd = 'tokenReward__untrackedVolumeUSD',
    TotalLp = 'totalLP',
    TotalLpUsd = 'totalLpUsd',
    TotalReward = 'totalReward',
    Withdraw = 'withdraw',
}

export type FarmingTransaction = {
    __typename?: 'FarmingTransaction'
    amount: Scalars['BigDecimal']['output']
    block: Scalars['BigInt']['output']
    farmingPool: FarmingPool
    id: Scalars['ID']['output']
    timestamp: Scalars['Int']['output']
    type: Scalars['String']['output']
    user: FarmingUser
}

export type FarmingTransaction_Filter = {
    /** Filter for the block changed event. */
    _change_block?: InputMaybe<BlockChangedFilter>
    amount?: InputMaybe<Scalars['BigDecimal']['input']>
    amount_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    amount_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    amount_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    amount_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    amount_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    amount_not?: InputMaybe<Scalars['BigDecimal']['input']>
    amount_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    and?: InputMaybe<Array<InputMaybe<FarmingTransaction_Filter>>>
    block?: InputMaybe<Scalars['BigInt']['input']>
    block_gt?: InputMaybe<Scalars['BigInt']['input']>
    block_gte?: InputMaybe<Scalars['BigInt']['input']>
    block_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    block_lt?: InputMaybe<Scalars['BigInt']['input']>
    block_lte?: InputMaybe<Scalars['BigInt']['input']>
    block_not?: InputMaybe<Scalars['BigInt']['input']>
    block_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    farmingPool?: InputMaybe<Scalars['String']['input']>
    farmingPool_?: InputMaybe<FarmingPool_Filter>
    farmingPool_contains?: InputMaybe<Scalars['String']['input']>
    farmingPool_contains_nocase?: InputMaybe<Scalars['String']['input']>
    farmingPool_ends_with?: InputMaybe<Scalars['String']['input']>
    farmingPool_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    farmingPool_gt?: InputMaybe<Scalars['String']['input']>
    farmingPool_gte?: InputMaybe<Scalars['String']['input']>
    farmingPool_in?: InputMaybe<Array<Scalars['String']['input']>>
    farmingPool_lt?: InputMaybe<Scalars['String']['input']>
    farmingPool_lte?: InputMaybe<Scalars['String']['input']>
    farmingPool_not?: InputMaybe<Scalars['String']['input']>
    farmingPool_not_contains?: InputMaybe<Scalars['String']['input']>
    farmingPool_not_contains_nocase?: InputMaybe<Scalars['String']['input']>
    farmingPool_not_ends_with?: InputMaybe<Scalars['String']['input']>
    farmingPool_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    farmingPool_not_in?: InputMaybe<Array<Scalars['String']['input']>>
    farmingPool_not_starts_with?: InputMaybe<Scalars['String']['input']>
    farmingPool_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    farmingPool_starts_with?: InputMaybe<Scalars['String']['input']>
    farmingPool_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    id_gt?: InputMaybe<Scalars['ID']['input']>
    id_gte?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<Scalars['ID']['input']>>
    id_lt?: InputMaybe<Scalars['ID']['input']>
    id_lte?: InputMaybe<Scalars['ID']['input']>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>
    or?: InputMaybe<Array<InputMaybe<FarmingTransaction_Filter>>>
    timestamp?: InputMaybe<Scalars['Int']['input']>
    timestamp_gt?: InputMaybe<Scalars['Int']['input']>
    timestamp_gte?: InputMaybe<Scalars['Int']['input']>
    timestamp_in?: InputMaybe<Array<Scalars['Int']['input']>>
    timestamp_lt?: InputMaybe<Scalars['Int']['input']>
    timestamp_lte?: InputMaybe<Scalars['Int']['input']>
    timestamp_not?: InputMaybe<Scalars['Int']['input']>
    timestamp_not_in?: InputMaybe<Array<Scalars['Int']['input']>>
    type?: InputMaybe<Scalars['String']['input']>
    type_contains?: InputMaybe<Scalars['String']['input']>
    type_contains_nocase?: InputMaybe<Scalars['String']['input']>
    type_ends_with?: InputMaybe<Scalars['String']['input']>
    type_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    type_gt?: InputMaybe<Scalars['String']['input']>
    type_gte?: InputMaybe<Scalars['String']['input']>
    type_in?: InputMaybe<Array<Scalars['String']['input']>>
    type_lt?: InputMaybe<Scalars['String']['input']>
    type_lte?: InputMaybe<Scalars['String']['input']>
    type_not?: InputMaybe<Scalars['String']['input']>
    type_not_contains?: InputMaybe<Scalars['String']['input']>
    type_not_contains_nocase?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    type_not_in?: InputMaybe<Array<Scalars['String']['input']>>
    type_not_starts_with?: InputMaybe<Scalars['String']['input']>
    type_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    type_starts_with?: InputMaybe<Scalars['String']['input']>
    type_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    user_?: InputMaybe<FarmingUser_Filter>
    user_contains?: InputMaybe<Scalars['String']['input']>
    user_contains_nocase?: InputMaybe<Scalars['String']['input']>
    user_ends_with?: InputMaybe<Scalars['String']['input']>
    user_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    user_gt?: InputMaybe<Scalars['String']['input']>
    user_gte?: InputMaybe<Scalars['String']['input']>
    user_in?: InputMaybe<Array<Scalars['String']['input']>>
    user_lt?: InputMaybe<Scalars['String']['input']>
    user_lte?: InputMaybe<Scalars['String']['input']>
    user_not?: InputMaybe<Scalars['String']['input']>
    user_not_contains?: InputMaybe<Scalars['String']['input']>
    user_not_contains_nocase?: InputMaybe<Scalars['String']['input']>
    user_not_ends_with?: InputMaybe<Scalars['String']['input']>
    user_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    user_not_in?: InputMaybe<Array<Scalars['String']['input']>>
    user_not_starts_with?: InputMaybe<Scalars['String']['input']>
    user_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    user_starts_with?: InputMaybe<Scalars['String']['input']>
    user_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
}

export enum FarmingTransaction_OrderBy {
    Amount = 'amount',
    Block = 'block',
    FarmingPool = 'farmingPool',
    FarmingPoolAccAmountPerShare = 'farmingPool__accAmountPerShare',
    FarmingPoolAmountPerSecond = 'farmingPool__amountPerSecond',
    FarmingPoolAmountPerSecondUsd = 'farmingPool__amountPerSecondUSD',
    FarmingPoolAmountReward = 'farmingPool__amountReward',
    FarmingPoolApr = 'farmingPool__apr',
    FarmingPoolEndDate = 'farmingPool__endDate',
    FarmingPoolHarvested = 'farmingPool__harvested',
    FarmingPoolId = 'farmingPool__id',
    FarmingPoolIsBlueCheck = 'farmingPool__isBlueCheck',
    FarmingPoolLastRewardTimestamp = 'farmingPool__lastRewardTimestamp',
    FarmingPoolOwner = 'farmingPool__owner',
    FarmingPoolSortIndex = 'farmingPool__sortIndex',
    FarmingPoolStartDate = 'farmingPool__startDate',
    FarmingPoolTotalLp = 'farmingPool__totalLP',
    FarmingPoolTotalLpUsd = 'farmingPool__totalLpUsd',
    FarmingPoolTotalReward = 'farmingPool__totalReward',
    FarmingPoolWithdraw = 'farmingPool__withdraw',
    Id = 'id',
    Timestamp = 'timestamp',
    Type = 'type',
    User = 'user',
    UserAddress = 'user__address',
    UserAmount = 'user__amount',
    UserEnded = 'user__ended',
    UserHarvested = 'user__harvested',
    UserId = 'user__id',
    UserRewardDebt = 'user__rewardDebt',
    UserRewardEarn = 'user__rewardEarn',
}

export type FarmingUser = {
    __typename?: 'FarmingUser'
    address: Scalars['Bytes']['output']
    amount: Scalars['BigDecimal']['output']
    ended: Scalars['Boolean']['output']
    farmingPool: FarmingPool
    harvested: Scalars['BigDecimal']['output']
    id: Scalars['ID']['output']
    rewardDebt: Scalars['BigDecimal']['output']
    rewardEarn: Scalars['BigDecimal']['output']
}

export type FarmingUser_Filter = {
    /** Filter for the block changed event. */
    _change_block?: InputMaybe<BlockChangedFilter>
    address?: InputMaybe<Scalars['Bytes']['input']>
    address_contains?: InputMaybe<Scalars['Bytes']['input']>
    address_gt?: InputMaybe<Scalars['Bytes']['input']>
    address_gte?: InputMaybe<Scalars['Bytes']['input']>
    address_in?: InputMaybe<Array<Scalars['Bytes']['input']>>
    address_lt?: InputMaybe<Scalars['Bytes']['input']>
    address_lte?: InputMaybe<Scalars['Bytes']['input']>
    address_not?: InputMaybe<Scalars['Bytes']['input']>
    address_not_contains?: InputMaybe<Scalars['Bytes']['input']>
    address_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>
    amount?: InputMaybe<Scalars['BigDecimal']['input']>
    amount_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    amount_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    amount_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    amount_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    amount_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    amount_not?: InputMaybe<Scalars['BigDecimal']['input']>
    amount_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    and?: InputMaybe<Array<InputMaybe<FarmingUser_Filter>>>
    ended?: InputMaybe<Scalars['Boolean']['input']>
    ended_in?: InputMaybe<Array<Scalars['Boolean']['input']>>
    ended_not?: InputMaybe<Scalars['Boolean']['input']>
    ended_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>
    farmingPool?: InputMaybe<Scalars['String']['input']>
    farmingPool_?: InputMaybe<FarmingPool_Filter>
    farmingPool_contains?: InputMaybe<Scalars['String']['input']>
    farmingPool_contains_nocase?: InputMaybe<Scalars['String']['input']>
    farmingPool_ends_with?: InputMaybe<Scalars['String']['input']>
    farmingPool_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    farmingPool_gt?: InputMaybe<Scalars['String']['input']>
    farmingPool_gte?: InputMaybe<Scalars['String']['input']>
    farmingPool_in?: InputMaybe<Array<Scalars['String']['input']>>
    farmingPool_lt?: InputMaybe<Scalars['String']['input']>
    farmingPool_lte?: InputMaybe<Scalars['String']['input']>
    farmingPool_not?: InputMaybe<Scalars['String']['input']>
    farmingPool_not_contains?: InputMaybe<Scalars['String']['input']>
    farmingPool_not_contains_nocase?: InputMaybe<Scalars['String']['input']>
    farmingPool_not_ends_with?: InputMaybe<Scalars['String']['input']>
    farmingPool_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    farmingPool_not_in?: InputMaybe<Array<Scalars['String']['input']>>
    farmingPool_not_starts_with?: InputMaybe<Scalars['String']['input']>
    farmingPool_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    farmingPool_starts_with?: InputMaybe<Scalars['String']['input']>
    farmingPool_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    harvested?: InputMaybe<Scalars['BigDecimal']['input']>
    harvested_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    harvested_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    harvested_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    harvested_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    harvested_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    harvested_not?: InputMaybe<Scalars['BigDecimal']['input']>
    harvested_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_gt?: InputMaybe<Scalars['ID']['input']>
    id_gte?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<Scalars['ID']['input']>>
    id_lt?: InputMaybe<Scalars['ID']['input']>
    id_lte?: InputMaybe<Scalars['ID']['input']>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>
    or?: InputMaybe<Array<InputMaybe<FarmingUser_Filter>>>
    rewardDebt?: InputMaybe<Scalars['BigDecimal']['input']>
    rewardDebt_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    rewardDebt_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    rewardDebt_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    rewardDebt_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    rewardDebt_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    rewardDebt_not?: InputMaybe<Scalars['BigDecimal']['input']>
    rewardDebt_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    rewardEarn?: InputMaybe<Scalars['BigDecimal']['input']>
    rewardEarn_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    rewardEarn_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    rewardEarn_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    rewardEarn_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    rewardEarn_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    rewardEarn_not?: InputMaybe<Scalars['BigDecimal']['input']>
    rewardEarn_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
}

export enum FarmingUser_OrderBy {
    Address = 'address',
    Amount = 'amount',
    Ended = 'ended',
    FarmingPool = 'farmingPool',
    FarmingPoolAccAmountPerShare = 'farmingPool__accAmountPerShare',
    FarmingPoolAmountPerSecond = 'farmingPool__amountPerSecond',
    FarmingPoolAmountPerSecondUsd = 'farmingPool__amountPerSecondUSD',
    FarmingPoolAmountReward = 'farmingPool__amountReward',
    FarmingPoolApr = 'farmingPool__apr',
    FarmingPoolEndDate = 'farmingPool__endDate',
    FarmingPoolHarvested = 'farmingPool__harvested',
    FarmingPoolId = 'farmingPool__id',
    FarmingPoolIsBlueCheck = 'farmingPool__isBlueCheck',
    FarmingPoolLastRewardTimestamp = 'farmingPool__lastRewardTimestamp',
    FarmingPoolOwner = 'farmingPool__owner',
    FarmingPoolSortIndex = 'farmingPool__sortIndex',
    FarmingPoolStartDate = 'farmingPool__startDate',
    FarmingPoolTotalLp = 'farmingPool__totalLP',
    FarmingPoolTotalLpUsd = 'farmingPool__totalLpUsd',
    FarmingPoolTotalReward = 'farmingPool__totalReward',
    FarmingPoolWithdraw = 'farmingPool__withdraw',
    Harvested = 'harvested',
    Id = 'id',
    RewardDebt = 'rewardDebt',
    RewardEarn = 'rewardEarn',
}

export type Mint = {
    __typename?: 'Mint'
    amount0?: Maybe<Scalars['BigDecimal']['output']>
    amount1?: Maybe<Scalars['BigDecimal']['output']>
    amountUSD?: Maybe<Scalars['BigDecimal']['output']>
    feeLiquidity?: Maybe<Scalars['BigDecimal']['output']>
    feeTo?: Maybe<Scalars['Bytes']['output']>
    id: Scalars['ID']['output']
    liquidity: Scalars['BigDecimal']['output']
    logIndex?: Maybe<Scalars['BigInt']['output']>
    pair: Pair
    sender?: Maybe<Scalars['Bytes']['output']>
    timestamp: Scalars['BigInt']['output']
    to: Scalars['Bytes']['output']
    transaction: Transaction
}

export type Mint_Filter = {
    /** Filter for the block changed event. */
    _change_block?: InputMaybe<BlockChangedFilter>
    amount0?: InputMaybe<Scalars['BigDecimal']['input']>
    amount0_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    amount0_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    amount0_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    amount0_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    amount0_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    amount0_not?: InputMaybe<Scalars['BigDecimal']['input']>
    amount0_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    amount1?: InputMaybe<Scalars['BigDecimal']['input']>
    amount1_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    amount1_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    amount1_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    amount1_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    amount1_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    amount1_not?: InputMaybe<Scalars['BigDecimal']['input']>
    amount1_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    amountUSD?: InputMaybe<Scalars['BigDecimal']['input']>
    amountUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    amountUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    amountUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    amountUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    amountUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    amountUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>
    amountUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    and?: InputMaybe<Array<InputMaybe<Mint_Filter>>>
    feeLiquidity?: InputMaybe<Scalars['BigDecimal']['input']>
    feeLiquidity_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    feeLiquidity_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    feeLiquidity_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    feeLiquidity_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    feeLiquidity_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    feeLiquidity_not?: InputMaybe<Scalars['BigDecimal']['input']>
    feeLiquidity_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    feeTo?: InputMaybe<Scalars['Bytes']['input']>
    feeTo_contains?: InputMaybe<Scalars['Bytes']['input']>
    feeTo_gt?: InputMaybe<Scalars['Bytes']['input']>
    feeTo_gte?: InputMaybe<Scalars['Bytes']['input']>
    feeTo_in?: InputMaybe<Array<Scalars['Bytes']['input']>>
    feeTo_lt?: InputMaybe<Scalars['Bytes']['input']>
    feeTo_lte?: InputMaybe<Scalars['Bytes']['input']>
    feeTo_not?: InputMaybe<Scalars['Bytes']['input']>
    feeTo_not_contains?: InputMaybe<Scalars['Bytes']['input']>
    feeTo_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_gt?: InputMaybe<Scalars['ID']['input']>
    id_gte?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<Scalars['ID']['input']>>
    id_lt?: InputMaybe<Scalars['ID']['input']>
    id_lte?: InputMaybe<Scalars['ID']['input']>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>
    liquidity?: InputMaybe<Scalars['BigDecimal']['input']>
    liquidity_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    liquidity_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    liquidity_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    liquidity_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    liquidity_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    liquidity_not?: InputMaybe<Scalars['BigDecimal']['input']>
    liquidity_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    logIndex?: InputMaybe<Scalars['BigInt']['input']>
    logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>
    logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>
    logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>
    logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>
    logIndex_not?: InputMaybe<Scalars['BigInt']['input']>
    logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    or?: InputMaybe<Array<InputMaybe<Mint_Filter>>>
    pair?: InputMaybe<Scalars['String']['input']>
    pair_?: InputMaybe<Pair_Filter>
    pair_contains?: InputMaybe<Scalars['String']['input']>
    pair_contains_nocase?: InputMaybe<Scalars['String']['input']>
    pair_ends_with?: InputMaybe<Scalars['String']['input']>
    pair_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    pair_gt?: InputMaybe<Scalars['String']['input']>
    pair_gte?: InputMaybe<Scalars['String']['input']>
    pair_in?: InputMaybe<Array<Scalars['String']['input']>>
    pair_lt?: InputMaybe<Scalars['String']['input']>
    pair_lte?: InputMaybe<Scalars['String']['input']>
    pair_not?: InputMaybe<Scalars['String']['input']>
    pair_not_contains?: InputMaybe<Scalars['String']['input']>
    pair_not_contains_nocase?: InputMaybe<Scalars['String']['input']>
    pair_not_ends_with?: InputMaybe<Scalars['String']['input']>
    pair_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    pair_not_in?: InputMaybe<Array<Scalars['String']['input']>>
    pair_not_starts_with?: InputMaybe<Scalars['String']['input']>
    pair_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    pair_starts_with?: InputMaybe<Scalars['String']['input']>
    pair_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['Bytes']['input']>
    sender_contains?: InputMaybe<Scalars['Bytes']['input']>
    sender_gt?: InputMaybe<Scalars['Bytes']['input']>
    sender_gte?: InputMaybe<Scalars['Bytes']['input']>
    sender_in?: InputMaybe<Array<Scalars['Bytes']['input']>>
    sender_lt?: InputMaybe<Scalars['Bytes']['input']>
    sender_lte?: InputMaybe<Scalars['Bytes']['input']>
    sender_not?: InputMaybe<Scalars['Bytes']['input']>
    sender_not_contains?: InputMaybe<Scalars['Bytes']['input']>
    sender_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>
    timestamp?: InputMaybe<Scalars['BigInt']['input']>
    timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>
    timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>
    timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>
    timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>
    timestamp_not?: InputMaybe<Scalars['BigInt']['input']>
    timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    to?: InputMaybe<Scalars['Bytes']['input']>
    to_contains?: InputMaybe<Scalars['Bytes']['input']>
    to_gt?: InputMaybe<Scalars['Bytes']['input']>
    to_gte?: InputMaybe<Scalars['Bytes']['input']>
    to_in?: InputMaybe<Array<Scalars['Bytes']['input']>>
    to_lt?: InputMaybe<Scalars['Bytes']['input']>
    to_lte?: InputMaybe<Scalars['Bytes']['input']>
    to_not?: InputMaybe<Scalars['Bytes']['input']>
    to_not_contains?: InputMaybe<Scalars['Bytes']['input']>
    to_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>
    transaction?: InputMaybe<Scalars['String']['input']>
    transaction_?: InputMaybe<Transaction_Filter>
    transaction_contains?: InputMaybe<Scalars['String']['input']>
    transaction_contains_nocase?: InputMaybe<Scalars['String']['input']>
    transaction_ends_with?: InputMaybe<Scalars['String']['input']>
    transaction_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    transaction_gt?: InputMaybe<Scalars['String']['input']>
    transaction_gte?: InputMaybe<Scalars['String']['input']>
    transaction_in?: InputMaybe<Array<Scalars['String']['input']>>
    transaction_lt?: InputMaybe<Scalars['String']['input']>
    transaction_lte?: InputMaybe<Scalars['String']['input']>
    transaction_not?: InputMaybe<Scalars['String']['input']>
    transaction_not_contains?: InputMaybe<Scalars['String']['input']>
    transaction_not_contains_nocase?: InputMaybe<Scalars['String']['input']>
    transaction_not_ends_with?: InputMaybe<Scalars['String']['input']>
    transaction_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    transaction_not_in?: InputMaybe<Array<Scalars['String']['input']>>
    transaction_not_starts_with?: InputMaybe<Scalars['String']['input']>
    transaction_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    transaction_starts_with?: InputMaybe<Scalars['String']['input']>
    transaction_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
}

export enum Mint_OrderBy {
    Amount0 = 'amount0',
    Amount1 = 'amount1',
    AmountUsd = 'amountUSD',
    FeeLiquidity = 'feeLiquidity',
    FeeTo = 'feeTo',
    Id = 'id',
    Liquidity = 'liquidity',
    LogIndex = 'logIndex',
    Pair = 'pair',
    PairBlock = 'pair__block',
    PairCurrentFee = 'pair__currentFee',
    PairId = 'pair__id',
    PairLpTokenPrice = 'pair__lpTokenPrice',
    PairName = 'pair__name',
    PairReserve0 = 'pair__reserve0',
    PairReserve1 = 'pair__reserve1',
    PairReserveEth = 'pair__reserveETH',
    PairReserveUsd = 'pair__reserveUSD',
    PairTimestamp = 'pair__timestamp',
    PairToken0Price = 'pair__token0Price',
    PairToken1Price = 'pair__token1Price',
    PairTotalFee = 'pair__totalFee',
    PairTotalSupply = 'pair__totalSupply',
    PairTotalTransactions = 'pair__totalTransactions',
    PairTrackedReserveEth = 'pair__trackedReserveETH',
    PairUntrackedVolumeUsd = 'pair__untrackedVolumeUSD',
    PairVolumeToken0 = 'pair__volumeToken0',
    PairVolumeToken1 = 'pair__volumeToken1',
    PairVolumeUsd = 'pair__volumeUSD',
    Sender = 'sender',
    Timestamp = 'timestamp',
    To = 'to',
    Transaction = 'transaction',
    TransactionBlock = 'transaction__block',
    TransactionId = 'transaction__id',
    TransactionTimestamp = 'transaction__timestamp',
}

/** Defines the order direction, either ascending or descending */
export enum OrderDirection {
    Asc = 'asc',
    Desc = 'desc',
}

export type Pair = {
    __typename?: 'Pair'
    block: Scalars['BigInt']['output']
    burns: Array<Burn>
    currentFee: Scalars['BigDecimal']['output']
    id: Scalars['ID']['output']
    lpTokenPrice: Scalars['BigDecimal']['output']
    mints: Array<Mint>
    name: Scalars['String']['output']
    pairDayData: Array<PairDayData>
    pairHourData: Array<PairHourData>
    reserve0: Scalars['BigDecimal']['output']
    reserve1: Scalars['BigDecimal']['output']
    reserveETH: Scalars['BigDecimal']['output']
    reserveUSD: Scalars['BigDecimal']['output']
    swaps: Array<Swap>
    timestamp: Scalars['BigInt']['output']
    token0: Token
    token0Price: Scalars['BigDecimal']['output']
    token1: Token
    token1Price: Scalars['BigDecimal']['output']
    totalFee: Scalars['BigDecimal']['output']
    totalSupply: Scalars['BigDecimal']['output']
    totalTransactions: Scalars['BigInt']['output']
    trackedReserveETH: Scalars['BigDecimal']['output']
    untrackedVolumeUSD: Scalars['BigDecimal']['output']
    volumeToken0: Scalars['BigDecimal']['output']
    volumeToken1: Scalars['BigDecimal']['output']
    volumeUSD: Scalars['BigDecimal']['output']
}

export type PairBurnsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Burn_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    where?: InputMaybe<Burn_Filter>
}

export type PairMintsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Mint_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    where?: InputMaybe<Mint_Filter>
}

export type PairPairDayDataArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<PairDayData_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    where?: InputMaybe<PairDayData_Filter>
}

export type PairPairHourDataArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<PairHourData_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    where?: InputMaybe<PairHourData_Filter>
}

export type PairSwapsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Swap_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    where?: InputMaybe<Swap_Filter>
}

export type PairDayData = {
    __typename?: 'PairDayData'
    dailyTxns: Scalars['BigInt']['output']
    dailyVolumeToken0: Scalars['BigDecimal']['output']
    dailyVolumeToken1: Scalars['BigDecimal']['output']
    dailyVolumeUSD: Scalars['BigDecimal']['output']
    date: Scalars['Int']['output']
    id: Scalars['ID']['output']
    pair: Pair
    pairAddress: Scalars['Bytes']['output']
    reserve0: Scalars['BigDecimal']['output']
    reserve1: Scalars['BigDecimal']['output']
    reserveUSD: Scalars['BigDecimal']['output']
    token0: Token
    token1: Token
    totalSupply: Scalars['BigDecimal']['output']
}

export type PairDayData_Filter = {
    /** Filter for the block changed event. */
    _change_block?: InputMaybe<BlockChangedFilter>
    and?: InputMaybe<Array<InputMaybe<PairDayData_Filter>>>
    dailyTxns?: InputMaybe<Scalars['BigInt']['input']>
    dailyTxns_gt?: InputMaybe<Scalars['BigInt']['input']>
    dailyTxns_gte?: InputMaybe<Scalars['BigInt']['input']>
    dailyTxns_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    dailyTxns_lt?: InputMaybe<Scalars['BigInt']['input']>
    dailyTxns_lte?: InputMaybe<Scalars['BigInt']['input']>
    dailyTxns_not?: InputMaybe<Scalars['BigInt']['input']>
    dailyTxns_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    dailyVolumeToken0?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeToken0_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeToken0_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeToken0_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    dailyVolumeToken0_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeToken0_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeToken0_not?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeToken0_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    dailyVolumeToken1?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeToken1_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeToken1_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeToken1_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    dailyVolumeToken1_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeToken1_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeToken1_not?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeToken1_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    dailyVolumeUSD?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    dailyVolumeUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    date?: InputMaybe<Scalars['Int']['input']>
    date_gt?: InputMaybe<Scalars['Int']['input']>
    date_gte?: InputMaybe<Scalars['Int']['input']>
    date_in?: InputMaybe<Array<Scalars['Int']['input']>>
    date_lt?: InputMaybe<Scalars['Int']['input']>
    date_lte?: InputMaybe<Scalars['Int']['input']>
    date_not?: InputMaybe<Scalars['Int']['input']>
    date_not_in?: InputMaybe<Array<Scalars['Int']['input']>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_gt?: InputMaybe<Scalars['ID']['input']>
    id_gte?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<Scalars['ID']['input']>>
    id_lt?: InputMaybe<Scalars['ID']['input']>
    id_lte?: InputMaybe<Scalars['ID']['input']>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>
    or?: InputMaybe<Array<InputMaybe<PairDayData_Filter>>>
    pair?: InputMaybe<Scalars['String']['input']>
    pairAddress?: InputMaybe<Scalars['Bytes']['input']>
    pairAddress_contains?: InputMaybe<Scalars['Bytes']['input']>
    pairAddress_gt?: InputMaybe<Scalars['Bytes']['input']>
    pairAddress_gte?: InputMaybe<Scalars['Bytes']['input']>
    pairAddress_in?: InputMaybe<Array<Scalars['Bytes']['input']>>
    pairAddress_lt?: InputMaybe<Scalars['Bytes']['input']>
    pairAddress_lte?: InputMaybe<Scalars['Bytes']['input']>
    pairAddress_not?: InputMaybe<Scalars['Bytes']['input']>
    pairAddress_not_contains?: InputMaybe<Scalars['Bytes']['input']>
    pairAddress_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>
    pair_?: InputMaybe<Pair_Filter>
    pair_contains?: InputMaybe<Scalars['String']['input']>
    pair_contains_nocase?: InputMaybe<Scalars['String']['input']>
    pair_ends_with?: InputMaybe<Scalars['String']['input']>
    pair_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    pair_gt?: InputMaybe<Scalars['String']['input']>
    pair_gte?: InputMaybe<Scalars['String']['input']>
    pair_in?: InputMaybe<Array<Scalars['String']['input']>>
    pair_lt?: InputMaybe<Scalars['String']['input']>
    pair_lte?: InputMaybe<Scalars['String']['input']>
    pair_not?: InputMaybe<Scalars['String']['input']>
    pair_not_contains?: InputMaybe<Scalars['String']['input']>
    pair_not_contains_nocase?: InputMaybe<Scalars['String']['input']>
    pair_not_ends_with?: InputMaybe<Scalars['String']['input']>
    pair_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    pair_not_in?: InputMaybe<Array<Scalars['String']['input']>>
    pair_not_starts_with?: InputMaybe<Scalars['String']['input']>
    pair_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    pair_starts_with?: InputMaybe<Scalars['String']['input']>
    pair_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    reserve0?: InputMaybe<Scalars['BigDecimal']['input']>
    reserve0_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    reserve0_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    reserve0_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    reserve0_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    reserve0_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    reserve0_not?: InputMaybe<Scalars['BigDecimal']['input']>
    reserve0_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    reserve1?: InputMaybe<Scalars['BigDecimal']['input']>
    reserve1_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    reserve1_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    reserve1_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    reserve1_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    reserve1_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    reserve1_not?: InputMaybe<Scalars['BigDecimal']['input']>
    reserve1_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    reserveUSD?: InputMaybe<Scalars['BigDecimal']['input']>
    reserveUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    reserveUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    reserveUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    reserveUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    reserveUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    reserveUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>
    reserveUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    token0?: InputMaybe<Scalars['String']['input']>
    token0_?: InputMaybe<Token_Filter>
    token0_contains?: InputMaybe<Scalars['String']['input']>
    token0_contains_nocase?: InputMaybe<Scalars['String']['input']>
    token0_ends_with?: InputMaybe<Scalars['String']['input']>
    token0_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    token0_gt?: InputMaybe<Scalars['String']['input']>
    token0_gte?: InputMaybe<Scalars['String']['input']>
    token0_in?: InputMaybe<Array<Scalars['String']['input']>>
    token0_lt?: InputMaybe<Scalars['String']['input']>
    token0_lte?: InputMaybe<Scalars['String']['input']>
    token0_not?: InputMaybe<Scalars['String']['input']>
    token0_not_contains?: InputMaybe<Scalars['String']['input']>
    token0_not_contains_nocase?: InputMaybe<Scalars['String']['input']>
    token0_not_ends_with?: InputMaybe<Scalars['String']['input']>
    token0_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    token0_not_in?: InputMaybe<Array<Scalars['String']['input']>>
    token0_not_starts_with?: InputMaybe<Scalars['String']['input']>
    token0_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    token0_starts_with?: InputMaybe<Scalars['String']['input']>
    token0_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    token1?: InputMaybe<Scalars['String']['input']>
    token1_?: InputMaybe<Token_Filter>
    token1_contains?: InputMaybe<Scalars['String']['input']>
    token1_contains_nocase?: InputMaybe<Scalars['String']['input']>
    token1_ends_with?: InputMaybe<Scalars['String']['input']>
    token1_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    token1_gt?: InputMaybe<Scalars['String']['input']>
    token1_gte?: InputMaybe<Scalars['String']['input']>
    token1_in?: InputMaybe<Array<Scalars['String']['input']>>
    token1_lt?: InputMaybe<Scalars['String']['input']>
    token1_lte?: InputMaybe<Scalars['String']['input']>
    token1_not?: InputMaybe<Scalars['String']['input']>
    token1_not_contains?: InputMaybe<Scalars['String']['input']>
    token1_not_contains_nocase?: InputMaybe<Scalars['String']['input']>
    token1_not_ends_with?: InputMaybe<Scalars['String']['input']>
    token1_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    token1_not_in?: InputMaybe<Array<Scalars['String']['input']>>
    token1_not_starts_with?: InputMaybe<Scalars['String']['input']>
    token1_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    token1_starts_with?: InputMaybe<Scalars['String']['input']>
    token1_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    totalSupply?: InputMaybe<Scalars['BigDecimal']['input']>
    totalSupply_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    totalSupply_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    totalSupply_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    totalSupply_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    totalSupply_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    totalSupply_not?: InputMaybe<Scalars['BigDecimal']['input']>
    totalSupply_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
}

export enum PairDayData_OrderBy {
    DailyTxns = 'dailyTxns',
    DailyVolumeToken0 = 'dailyVolumeToken0',
    DailyVolumeToken1 = 'dailyVolumeToken1',
    DailyVolumeUsd = 'dailyVolumeUSD',
    Date = 'date',
    Id = 'id',
    Pair = 'pair',
    PairAddress = 'pairAddress',
    PairBlock = 'pair__block',
    PairCurrentFee = 'pair__currentFee',
    PairId = 'pair__id',
    PairLpTokenPrice = 'pair__lpTokenPrice',
    PairName = 'pair__name',
    PairReserve0 = 'pair__reserve0',
    PairReserve1 = 'pair__reserve1',
    PairReserveEth = 'pair__reserveETH',
    PairReserveUsd = 'pair__reserveUSD',
    PairTimestamp = 'pair__timestamp',
    PairToken0Price = 'pair__token0Price',
    PairToken1Price = 'pair__token1Price',
    PairTotalFee = 'pair__totalFee',
    PairTotalSupply = 'pair__totalSupply',
    PairTotalTransactions = 'pair__totalTransactions',
    PairTrackedReserveEth = 'pair__trackedReserveETH',
    PairUntrackedVolumeUsd = 'pair__untrackedVolumeUSD',
    PairVolumeToken0 = 'pair__volumeToken0',
    PairVolumeToken1 = 'pair__volumeToken1',
    PairVolumeUsd = 'pair__volumeUSD',
    Reserve0 = 'reserve0',
    Reserve1 = 'reserve1',
    ReserveUsd = 'reserveUSD',
    Token0 = 'token0',
    Token0Decimals = 'token0__decimals',
    Token0DerivedEth = 'token0__derivedETH',
    Token0DerivedUsd = 'token0__derivedUSD',
    Token0Id = 'token0__id',
    Token0Name = 'token0__name',
    Token0Symbol = 'token0__symbol',
    Token0TotalLiquidity = 'token0__totalLiquidity',
    Token0TotalTransactions = 'token0__totalTransactions',
    Token0TradeVolume = 'token0__tradeVolume',
    Token0TradeVolumeUsd = 'token0__tradeVolumeUSD',
    Token0UntrackedVolumeUsd = 'token0__untrackedVolumeUSD',
    Token1 = 'token1',
    Token1Decimals = 'token1__decimals',
    Token1DerivedEth = 'token1__derivedETH',
    Token1DerivedUsd = 'token1__derivedUSD',
    Token1Id = 'token1__id',
    Token1Name = 'token1__name',
    Token1Symbol = 'token1__symbol',
    Token1TotalLiquidity = 'token1__totalLiquidity',
    Token1TotalTransactions = 'token1__totalTransactions',
    Token1TradeVolume = 'token1__tradeVolume',
    Token1TradeVolumeUsd = 'token1__tradeVolumeUSD',
    Token1UntrackedVolumeUsd = 'token1__untrackedVolumeUSD',
    TotalSupply = 'totalSupply',
}

export type PairHourData = {
    __typename?: 'PairHourData'
    hourStartUnix: Scalars['Int']['output']
    hourlyTxns: Scalars['BigInt']['output']
    hourlyVolumeToken0: Scalars['BigDecimal']['output']
    hourlyVolumeToken1: Scalars['BigDecimal']['output']
    hourlyVolumeUSD: Scalars['BigDecimal']['output']
    id: Scalars['ID']['output']
    pair: Pair
    reserve0: Scalars['BigDecimal']['output']
    reserve1: Scalars['BigDecimal']['output']
    reserveUSD: Scalars['BigDecimal']['output']
    totalSupply: Scalars['BigDecimal']['output']
}

export type PairHourData_Filter = {
    /** Filter for the block changed event. */
    _change_block?: InputMaybe<BlockChangedFilter>
    and?: InputMaybe<Array<InputMaybe<PairHourData_Filter>>>
    hourStartUnix?: InputMaybe<Scalars['Int']['input']>
    hourStartUnix_gt?: InputMaybe<Scalars['Int']['input']>
    hourStartUnix_gte?: InputMaybe<Scalars['Int']['input']>
    hourStartUnix_in?: InputMaybe<Array<Scalars['Int']['input']>>
    hourStartUnix_lt?: InputMaybe<Scalars['Int']['input']>
    hourStartUnix_lte?: InputMaybe<Scalars['Int']['input']>
    hourStartUnix_not?: InputMaybe<Scalars['Int']['input']>
    hourStartUnix_not_in?: InputMaybe<Array<Scalars['Int']['input']>>
    hourlyTxns?: InputMaybe<Scalars['BigInt']['input']>
    hourlyTxns_gt?: InputMaybe<Scalars['BigInt']['input']>
    hourlyTxns_gte?: InputMaybe<Scalars['BigInt']['input']>
    hourlyTxns_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    hourlyTxns_lt?: InputMaybe<Scalars['BigInt']['input']>
    hourlyTxns_lte?: InputMaybe<Scalars['BigInt']['input']>
    hourlyTxns_not?: InputMaybe<Scalars['BigInt']['input']>
    hourlyTxns_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    hourlyVolumeToken0?: InputMaybe<Scalars['BigDecimal']['input']>
    hourlyVolumeToken0_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    hourlyVolumeToken0_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    hourlyVolumeToken0_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    hourlyVolumeToken0_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    hourlyVolumeToken0_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    hourlyVolumeToken0_not?: InputMaybe<Scalars['BigDecimal']['input']>
    hourlyVolumeToken0_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    hourlyVolumeToken1?: InputMaybe<Scalars['BigDecimal']['input']>
    hourlyVolumeToken1_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    hourlyVolumeToken1_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    hourlyVolumeToken1_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    hourlyVolumeToken1_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    hourlyVolumeToken1_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    hourlyVolumeToken1_not?: InputMaybe<Scalars['BigDecimal']['input']>
    hourlyVolumeToken1_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    hourlyVolumeUSD?: InputMaybe<Scalars['BigDecimal']['input']>
    hourlyVolumeUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    hourlyVolumeUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    hourlyVolumeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    hourlyVolumeUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    hourlyVolumeUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    hourlyVolumeUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>
    hourlyVolumeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_gt?: InputMaybe<Scalars['ID']['input']>
    id_gte?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<Scalars['ID']['input']>>
    id_lt?: InputMaybe<Scalars['ID']['input']>
    id_lte?: InputMaybe<Scalars['ID']['input']>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>
    or?: InputMaybe<Array<InputMaybe<PairHourData_Filter>>>
    pair?: InputMaybe<Scalars['String']['input']>
    pair_?: InputMaybe<Pair_Filter>
    pair_contains?: InputMaybe<Scalars['String']['input']>
    pair_contains_nocase?: InputMaybe<Scalars['String']['input']>
    pair_ends_with?: InputMaybe<Scalars['String']['input']>
    pair_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    pair_gt?: InputMaybe<Scalars['String']['input']>
    pair_gte?: InputMaybe<Scalars['String']['input']>
    pair_in?: InputMaybe<Array<Scalars['String']['input']>>
    pair_lt?: InputMaybe<Scalars['String']['input']>
    pair_lte?: InputMaybe<Scalars['String']['input']>
    pair_not?: InputMaybe<Scalars['String']['input']>
    pair_not_contains?: InputMaybe<Scalars['String']['input']>
    pair_not_contains_nocase?: InputMaybe<Scalars['String']['input']>
    pair_not_ends_with?: InputMaybe<Scalars['String']['input']>
    pair_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    pair_not_in?: InputMaybe<Array<Scalars['String']['input']>>
    pair_not_starts_with?: InputMaybe<Scalars['String']['input']>
    pair_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    pair_starts_with?: InputMaybe<Scalars['String']['input']>
    pair_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    reserve0?: InputMaybe<Scalars['BigDecimal']['input']>
    reserve0_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    reserve0_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    reserve0_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    reserve0_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    reserve0_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    reserve0_not?: InputMaybe<Scalars['BigDecimal']['input']>
    reserve0_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    reserve1?: InputMaybe<Scalars['BigDecimal']['input']>
    reserve1_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    reserve1_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    reserve1_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    reserve1_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    reserve1_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    reserve1_not?: InputMaybe<Scalars['BigDecimal']['input']>
    reserve1_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    reserveUSD?: InputMaybe<Scalars['BigDecimal']['input']>
    reserveUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    reserveUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    reserveUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    reserveUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    reserveUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    reserveUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>
    reserveUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    totalSupply?: InputMaybe<Scalars['BigDecimal']['input']>
    totalSupply_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    totalSupply_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    totalSupply_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    totalSupply_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    totalSupply_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    totalSupply_not?: InputMaybe<Scalars['BigDecimal']['input']>
    totalSupply_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
}

export enum PairHourData_OrderBy {
    HourStartUnix = 'hourStartUnix',
    HourlyTxns = 'hourlyTxns',
    HourlyVolumeToken0 = 'hourlyVolumeToken0',
    HourlyVolumeToken1 = 'hourlyVolumeToken1',
    HourlyVolumeUsd = 'hourlyVolumeUSD',
    Id = 'id',
    Pair = 'pair',
    PairBlock = 'pair__block',
    PairCurrentFee = 'pair__currentFee',
    PairId = 'pair__id',
    PairLpTokenPrice = 'pair__lpTokenPrice',
    PairName = 'pair__name',
    PairReserve0 = 'pair__reserve0',
    PairReserve1 = 'pair__reserve1',
    PairReserveEth = 'pair__reserveETH',
    PairReserveUsd = 'pair__reserveUSD',
    PairTimestamp = 'pair__timestamp',
    PairToken0Price = 'pair__token0Price',
    PairToken1Price = 'pair__token1Price',
    PairTotalFee = 'pair__totalFee',
    PairTotalSupply = 'pair__totalSupply',
    PairTotalTransactions = 'pair__totalTransactions',
    PairTrackedReserveEth = 'pair__trackedReserveETH',
    PairUntrackedVolumeUsd = 'pair__untrackedVolumeUSD',
    PairVolumeToken0 = 'pair__volumeToken0',
    PairVolumeToken1 = 'pair__volumeToken1',
    PairVolumeUsd = 'pair__volumeUSD',
    Reserve0 = 'reserve0',
    Reserve1 = 'reserve1',
    ReserveUsd = 'reserveUSD',
    TotalSupply = 'totalSupply',
}

export type Pair_Filter = {
    /** Filter for the block changed event. */
    _change_block?: InputMaybe<BlockChangedFilter>
    and?: InputMaybe<Array<InputMaybe<Pair_Filter>>>
    block?: InputMaybe<Scalars['BigInt']['input']>
    block_gt?: InputMaybe<Scalars['BigInt']['input']>
    block_gte?: InputMaybe<Scalars['BigInt']['input']>
    block_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    block_lt?: InputMaybe<Scalars['BigInt']['input']>
    block_lte?: InputMaybe<Scalars['BigInt']['input']>
    block_not?: InputMaybe<Scalars['BigInt']['input']>
    block_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    burns_?: InputMaybe<Burn_Filter>
    currentFee?: InputMaybe<Scalars['BigDecimal']['input']>
    currentFee_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    currentFee_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    currentFee_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    currentFee_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    currentFee_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    currentFee_not?: InputMaybe<Scalars['BigDecimal']['input']>
    currentFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_gt?: InputMaybe<Scalars['ID']['input']>
    id_gte?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<Scalars['ID']['input']>>
    id_lt?: InputMaybe<Scalars['ID']['input']>
    id_lte?: InputMaybe<Scalars['ID']['input']>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>
    lpTokenPrice?: InputMaybe<Scalars['BigDecimal']['input']>
    lpTokenPrice_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    lpTokenPrice_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    lpTokenPrice_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    lpTokenPrice_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    lpTokenPrice_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    lpTokenPrice_not?: InputMaybe<Scalars['BigDecimal']['input']>
    lpTokenPrice_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    mints_?: InputMaybe<Mint_Filter>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_nocase?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    name_gt?: InputMaybe<Scalars['String']['input']>
    name_gte?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<Scalars['String']['input']>>
    name_lt?: InputMaybe<Scalars['String']['input']>
    name_lte?: InputMaybe<Scalars['String']['input']>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<Scalars['String']['input']>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    or?: InputMaybe<Array<InputMaybe<Pair_Filter>>>
    pairDayData_?: InputMaybe<PairDayData_Filter>
    pairHourData_?: InputMaybe<PairHourData_Filter>
    reserve0?: InputMaybe<Scalars['BigDecimal']['input']>
    reserve0_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    reserve0_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    reserve0_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    reserve0_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    reserve0_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    reserve0_not?: InputMaybe<Scalars['BigDecimal']['input']>
    reserve0_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    reserve1?: InputMaybe<Scalars['BigDecimal']['input']>
    reserve1_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    reserve1_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    reserve1_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    reserve1_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    reserve1_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    reserve1_not?: InputMaybe<Scalars['BigDecimal']['input']>
    reserve1_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    reserveETH?: InputMaybe<Scalars['BigDecimal']['input']>
    reserveETH_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    reserveETH_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    reserveETH_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    reserveETH_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    reserveETH_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    reserveETH_not?: InputMaybe<Scalars['BigDecimal']['input']>
    reserveETH_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    reserveUSD?: InputMaybe<Scalars['BigDecimal']['input']>
    reserveUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    reserveUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    reserveUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    reserveUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    reserveUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    reserveUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>
    reserveUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    swaps_?: InputMaybe<Swap_Filter>
    timestamp?: InputMaybe<Scalars['BigInt']['input']>
    timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>
    timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>
    timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>
    timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>
    timestamp_not?: InputMaybe<Scalars['BigInt']['input']>
    timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    token0?: InputMaybe<Scalars['String']['input']>
    token0Price?: InputMaybe<Scalars['BigDecimal']['input']>
    token0Price_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    token0Price_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    token0Price_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    token0Price_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    token0Price_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    token0Price_not?: InputMaybe<Scalars['BigDecimal']['input']>
    token0Price_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    token0_?: InputMaybe<Token_Filter>
    token0_contains?: InputMaybe<Scalars['String']['input']>
    token0_contains_nocase?: InputMaybe<Scalars['String']['input']>
    token0_ends_with?: InputMaybe<Scalars['String']['input']>
    token0_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    token0_gt?: InputMaybe<Scalars['String']['input']>
    token0_gte?: InputMaybe<Scalars['String']['input']>
    token0_in?: InputMaybe<Array<Scalars['String']['input']>>
    token0_lt?: InputMaybe<Scalars['String']['input']>
    token0_lte?: InputMaybe<Scalars['String']['input']>
    token0_not?: InputMaybe<Scalars['String']['input']>
    token0_not_contains?: InputMaybe<Scalars['String']['input']>
    token0_not_contains_nocase?: InputMaybe<Scalars['String']['input']>
    token0_not_ends_with?: InputMaybe<Scalars['String']['input']>
    token0_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    token0_not_in?: InputMaybe<Array<Scalars['String']['input']>>
    token0_not_starts_with?: InputMaybe<Scalars['String']['input']>
    token0_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    token0_starts_with?: InputMaybe<Scalars['String']['input']>
    token0_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    token1?: InputMaybe<Scalars['String']['input']>
    token1Price?: InputMaybe<Scalars['BigDecimal']['input']>
    token1Price_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    token1Price_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    token1Price_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    token1Price_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    token1Price_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    token1Price_not?: InputMaybe<Scalars['BigDecimal']['input']>
    token1Price_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    token1_?: InputMaybe<Token_Filter>
    token1_contains?: InputMaybe<Scalars['String']['input']>
    token1_contains_nocase?: InputMaybe<Scalars['String']['input']>
    token1_ends_with?: InputMaybe<Scalars['String']['input']>
    token1_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    token1_gt?: InputMaybe<Scalars['String']['input']>
    token1_gte?: InputMaybe<Scalars['String']['input']>
    token1_in?: InputMaybe<Array<Scalars['String']['input']>>
    token1_lt?: InputMaybe<Scalars['String']['input']>
    token1_lte?: InputMaybe<Scalars['String']['input']>
    token1_not?: InputMaybe<Scalars['String']['input']>
    token1_not_contains?: InputMaybe<Scalars['String']['input']>
    token1_not_contains_nocase?: InputMaybe<Scalars['String']['input']>
    token1_not_ends_with?: InputMaybe<Scalars['String']['input']>
    token1_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    token1_not_in?: InputMaybe<Array<Scalars['String']['input']>>
    token1_not_starts_with?: InputMaybe<Scalars['String']['input']>
    token1_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    token1_starts_with?: InputMaybe<Scalars['String']['input']>
    token1_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    totalFee?: InputMaybe<Scalars['BigDecimal']['input']>
    totalFee_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    totalFee_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    totalFee_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    totalFee_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    totalFee_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    totalFee_not?: InputMaybe<Scalars['BigDecimal']['input']>
    totalFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    totalSupply?: InputMaybe<Scalars['BigDecimal']['input']>
    totalSupply_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    totalSupply_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    totalSupply_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    totalSupply_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    totalSupply_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    totalSupply_not?: InputMaybe<Scalars['BigDecimal']['input']>
    totalSupply_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    totalTransactions?: InputMaybe<Scalars['BigInt']['input']>
    totalTransactions_gt?: InputMaybe<Scalars['BigInt']['input']>
    totalTransactions_gte?: InputMaybe<Scalars['BigInt']['input']>
    totalTransactions_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    totalTransactions_lt?: InputMaybe<Scalars['BigInt']['input']>
    totalTransactions_lte?: InputMaybe<Scalars['BigInt']['input']>
    totalTransactions_not?: InputMaybe<Scalars['BigInt']['input']>
    totalTransactions_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    trackedReserveETH?: InputMaybe<Scalars['BigDecimal']['input']>
    trackedReserveETH_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    trackedReserveETH_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    trackedReserveETH_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    trackedReserveETH_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    trackedReserveETH_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    trackedReserveETH_not?: InputMaybe<Scalars['BigDecimal']['input']>
    trackedReserveETH_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    untrackedVolumeUSD?: InputMaybe<Scalars['BigDecimal']['input']>
    untrackedVolumeUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    untrackedVolumeUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    untrackedVolumeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    untrackedVolumeUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    untrackedVolumeUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    untrackedVolumeUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>
    untrackedVolumeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    volumeToken0?: InputMaybe<Scalars['BigDecimal']['input']>
    volumeToken0_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    volumeToken0_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    volumeToken0_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    volumeToken0_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    volumeToken0_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    volumeToken0_not?: InputMaybe<Scalars['BigDecimal']['input']>
    volumeToken0_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    volumeToken1?: InputMaybe<Scalars['BigDecimal']['input']>
    volumeToken1_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    volumeToken1_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    volumeToken1_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    volumeToken1_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    volumeToken1_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    volumeToken1_not?: InputMaybe<Scalars['BigDecimal']['input']>
    volumeToken1_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    volumeUSD?: InputMaybe<Scalars['BigDecimal']['input']>
    volumeUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    volumeUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    volumeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    volumeUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    volumeUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    volumeUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>
    volumeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
}

export enum Pair_OrderBy {
    Block = 'block',
    Burns = 'burns',
    CurrentFee = 'currentFee',
    Id = 'id',
    LpTokenPrice = 'lpTokenPrice',
    Mints = 'mints',
    Name = 'name',
    PairDayData = 'pairDayData',
    PairHourData = 'pairHourData',
    Reserve0 = 'reserve0',
    Reserve1 = 'reserve1',
    ReserveEth = 'reserveETH',
    ReserveUsd = 'reserveUSD',
    Swaps = 'swaps',
    Timestamp = 'timestamp',
    Token0 = 'token0',
    Token0Price = 'token0Price',
    Token0Decimals = 'token0__decimals',
    Token0DerivedEth = 'token0__derivedETH',
    Token0DerivedUsd = 'token0__derivedUSD',
    Token0Id = 'token0__id',
    Token0Name = 'token0__name',
    Token0Symbol = 'token0__symbol',
    Token0TotalLiquidity = 'token0__totalLiquidity',
    Token0TotalTransactions = 'token0__totalTransactions',
    Token0TradeVolume = 'token0__tradeVolume',
    Token0TradeVolumeUsd = 'token0__tradeVolumeUSD',
    Token0UntrackedVolumeUsd = 'token0__untrackedVolumeUSD',
    Token1 = 'token1',
    Token1Price = 'token1Price',
    Token1Decimals = 'token1__decimals',
    Token1DerivedEth = 'token1__derivedETH',
    Token1DerivedUsd = 'token1__derivedUSD',
    Token1Id = 'token1__id',
    Token1Name = 'token1__name',
    Token1Symbol = 'token1__symbol',
    Token1TotalLiquidity = 'token1__totalLiquidity',
    Token1TotalTransactions = 'token1__totalTransactions',
    Token1TradeVolume = 'token1__tradeVolume',
    Token1TradeVolumeUsd = 'token1__tradeVolumeUSD',
    Token1UntrackedVolumeUsd = 'token1__untrackedVolumeUSD',
    TotalFee = 'totalFee',
    TotalSupply = 'totalSupply',
    TotalTransactions = 'totalTransactions',
    TrackedReserveEth = 'trackedReserveETH',
    UntrackedVolumeUsd = 'untrackedVolumeUSD',
    VolumeToken0 = 'volumeToken0',
    VolumeToken1 = 'volumeToken1',
    VolumeUsd = 'volumeUSD',
}

export type Query = {
    __typename?: 'Query'
    /** Access to subgraph metadata */
    _meta?: Maybe<_Meta_>
    bundle?: Maybe<Bundle>
    bundles: Array<Bundle>
    burn?: Maybe<Burn>
    burns: Array<Burn>
    echodexDayData?: Maybe<EchodexDayData>
    echodexDayDatas: Array<EchodexDayData>
    echodexFactories: Array<EchodexFactory>
    echodexFactory?: Maybe<EchodexFactory>
    farmingPool?: Maybe<FarmingPool>
    farmingPools: Array<FarmingPool>
    farmingTransaction?: Maybe<FarmingTransaction>
    farmingTransactions: Array<FarmingTransaction>
    farmingUser?: Maybe<FarmingUser>
    farmingUsers: Array<FarmingUser>
    mint?: Maybe<Mint>
    mints: Array<Mint>
    pair?: Maybe<Pair>
    pairDayData?: Maybe<PairDayData>
    pairDayDatas: Array<PairDayData>
    pairHourData?: Maybe<PairHourData>
    pairHourDatas: Array<PairHourData>
    pairSearch: Array<Pair>
    pairs: Array<Pair>
    swap?: Maybe<Swap>
    swaps: Array<Swap>
    token?: Maybe<Token>
    tokenDayData?: Maybe<TokenDayData>
    tokenDayDatas: Array<TokenDayData>
    tokenSearch: Array<Token>
    tokens: Array<Token>
    transaction?: Maybe<Transaction>
    transactions: Array<Transaction>
    user?: Maybe<User>
    userLiquidities: Array<UserLiquidity>
    userLiquidity?: Maybe<UserLiquidity>
    userSwapDayData?: Maybe<UserSwapDayData>
    userSwapDayDatas: Array<UserSwapDayData>
    users: Array<User>
    xecpRedeem?: Maybe<XecpRedeem>
    xecpRedeems: Array<XecpRedeem>
}

export type Query_MetaArgs = {
    block?: InputMaybe<Block_Height>
}

export type QueryBundleArgs = {
    block?: InputMaybe<Block_Height>
    id: Scalars['ID']['input']
    subgraphError?: _SubgraphErrorPolicy_
}

export type QueryBundlesArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Bundle_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    where?: InputMaybe<Bundle_Filter>
}

export type QueryBurnArgs = {
    block?: InputMaybe<Block_Height>
    id: Scalars['ID']['input']
    subgraphError?: _SubgraphErrorPolicy_
}

export type QueryBurnsArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Burn_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    where?: InputMaybe<Burn_Filter>
}

export type QueryEchodexDayDataArgs = {
    block?: InputMaybe<Block_Height>
    id: Scalars['ID']['input']
    subgraphError?: _SubgraphErrorPolicy_
}

export type QueryEchodexDayDatasArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<EchodexDayData_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    where?: InputMaybe<EchodexDayData_Filter>
}

export type QueryEchodexFactoriesArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<EchodexFactory_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    where?: InputMaybe<EchodexFactory_Filter>
}

export type QueryEchodexFactoryArgs = {
    block?: InputMaybe<Block_Height>
    id: Scalars['ID']['input']
    subgraphError?: _SubgraphErrorPolicy_
}

export type QueryFarmingPoolArgs = {
    block?: InputMaybe<Block_Height>
    id: Scalars['ID']['input']
    subgraphError?: _SubgraphErrorPolicy_
}

export type QueryFarmingPoolsArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<FarmingPool_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    where?: InputMaybe<FarmingPool_Filter>
}

export type QueryFarmingTransactionArgs = {
    block?: InputMaybe<Block_Height>
    id: Scalars['ID']['input']
    subgraphError?: _SubgraphErrorPolicy_
}

export type QueryFarmingTransactionsArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<FarmingTransaction_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    where?: InputMaybe<FarmingTransaction_Filter>
}

export type QueryFarmingUserArgs = {
    block?: InputMaybe<Block_Height>
    id: Scalars['ID']['input']
    subgraphError?: _SubgraphErrorPolicy_
}

export type QueryFarmingUsersArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<FarmingUser_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    where?: InputMaybe<FarmingUser_Filter>
}

export type QueryMintArgs = {
    block?: InputMaybe<Block_Height>
    id: Scalars['ID']['input']
    subgraphError?: _SubgraphErrorPolicy_
}

export type QueryMintsArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Mint_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    where?: InputMaybe<Mint_Filter>
}

export type QueryPairArgs = {
    block?: InputMaybe<Block_Height>
    id: Scalars['ID']['input']
    subgraphError?: _SubgraphErrorPolicy_
}

export type QueryPairDayDataArgs = {
    block?: InputMaybe<Block_Height>
    id: Scalars['ID']['input']
    subgraphError?: _SubgraphErrorPolicy_
}

export type QueryPairDayDatasArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<PairDayData_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    where?: InputMaybe<PairDayData_Filter>
}

export type QueryPairHourDataArgs = {
    block?: InputMaybe<Block_Height>
    id: Scalars['ID']['input']
    subgraphError?: _SubgraphErrorPolicy_
}

export type QueryPairHourDatasArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<PairHourData_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    where?: InputMaybe<PairHourData_Filter>
}

export type QueryPairSearchArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    text: Scalars['String']['input']
    where?: InputMaybe<Pair_Filter>
}

export type QueryPairsArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Pair_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    where?: InputMaybe<Pair_Filter>
}

export type QuerySwapArgs = {
    block?: InputMaybe<Block_Height>
    id: Scalars['ID']['input']
    subgraphError?: _SubgraphErrorPolicy_
}

export type QuerySwapsArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Swap_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    where?: InputMaybe<Swap_Filter>
}

export type QueryTokenArgs = {
    block?: InputMaybe<Block_Height>
    id: Scalars['ID']['input']
    subgraphError?: _SubgraphErrorPolicy_
}

export type QueryTokenDayDataArgs = {
    block?: InputMaybe<Block_Height>
    id: Scalars['ID']['input']
    subgraphError?: _SubgraphErrorPolicy_
}

export type QueryTokenDayDatasArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<TokenDayData_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    where?: InputMaybe<TokenDayData_Filter>
}

export type QueryTokenSearchArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    text: Scalars['String']['input']
    where?: InputMaybe<Token_Filter>
}

export type QueryTokensArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Token_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    where?: InputMaybe<Token_Filter>
}

export type QueryTransactionArgs = {
    block?: InputMaybe<Block_Height>
    id: Scalars['ID']['input']
    subgraphError?: _SubgraphErrorPolicy_
}

export type QueryTransactionsArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Transaction_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    where?: InputMaybe<Transaction_Filter>
}

export type QueryUserArgs = {
    block?: InputMaybe<Block_Height>
    id: Scalars['ID']['input']
    subgraphError?: _SubgraphErrorPolicy_
}

export type QueryUserLiquiditiesArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<UserLiquidity_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    where?: InputMaybe<UserLiquidity_Filter>
}

export type QueryUserLiquidityArgs = {
    block?: InputMaybe<Block_Height>
    id: Scalars['ID']['input']
    subgraphError?: _SubgraphErrorPolicy_
}

export type QueryUserSwapDayDataArgs = {
    block?: InputMaybe<Block_Height>
    id: Scalars['ID']['input']
    subgraphError?: _SubgraphErrorPolicy_
}

export type QueryUserSwapDayDatasArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<UserSwapDayData_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    where?: InputMaybe<UserSwapDayData_Filter>
}

export type QueryUsersArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<User_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    where?: InputMaybe<User_Filter>
}

export type QueryXecpRedeemArgs = {
    block?: InputMaybe<Block_Height>
    id: Scalars['ID']['input']
    subgraphError?: _SubgraphErrorPolicy_
}

export type QueryXecpRedeemsArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<XecpRedeem_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    where?: InputMaybe<XecpRedeem_Filter>
}

export type Subscription = {
    __typename?: 'Subscription'
    /** Access to subgraph metadata */
    _meta?: Maybe<_Meta_>
    bundle?: Maybe<Bundle>
    bundles: Array<Bundle>
    burn?: Maybe<Burn>
    burns: Array<Burn>
    echodexDayData?: Maybe<EchodexDayData>
    echodexDayDatas: Array<EchodexDayData>
    echodexFactories: Array<EchodexFactory>
    echodexFactory?: Maybe<EchodexFactory>
    farmingPool?: Maybe<FarmingPool>
    farmingPools: Array<FarmingPool>
    farmingTransaction?: Maybe<FarmingTransaction>
    farmingTransactions: Array<FarmingTransaction>
    farmingUser?: Maybe<FarmingUser>
    farmingUsers: Array<FarmingUser>
    mint?: Maybe<Mint>
    mints: Array<Mint>
    pair?: Maybe<Pair>
    pairDayData?: Maybe<PairDayData>
    pairDayDatas: Array<PairDayData>
    pairHourData?: Maybe<PairHourData>
    pairHourDatas: Array<PairHourData>
    pairs: Array<Pair>
    swap?: Maybe<Swap>
    swaps: Array<Swap>
    token?: Maybe<Token>
    tokenDayData?: Maybe<TokenDayData>
    tokenDayDatas: Array<TokenDayData>
    tokens: Array<Token>
    transaction?: Maybe<Transaction>
    transactions: Array<Transaction>
    user?: Maybe<User>
    userLiquidities: Array<UserLiquidity>
    userLiquidity?: Maybe<UserLiquidity>
    userSwapDayData?: Maybe<UserSwapDayData>
    userSwapDayDatas: Array<UserSwapDayData>
    users: Array<User>
    xecpRedeem?: Maybe<XecpRedeem>
    xecpRedeems: Array<XecpRedeem>
}

export type Subscription_MetaArgs = {
    block?: InputMaybe<Block_Height>
}

export type SubscriptionBundleArgs = {
    block?: InputMaybe<Block_Height>
    id: Scalars['ID']['input']
    subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionBundlesArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Bundle_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    where?: InputMaybe<Bundle_Filter>
}

export type SubscriptionBurnArgs = {
    block?: InputMaybe<Block_Height>
    id: Scalars['ID']['input']
    subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionBurnsArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Burn_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    where?: InputMaybe<Burn_Filter>
}

export type SubscriptionEchodexDayDataArgs = {
    block?: InputMaybe<Block_Height>
    id: Scalars['ID']['input']
    subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionEchodexDayDatasArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<EchodexDayData_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    where?: InputMaybe<EchodexDayData_Filter>
}

export type SubscriptionEchodexFactoriesArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<EchodexFactory_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    where?: InputMaybe<EchodexFactory_Filter>
}

export type SubscriptionEchodexFactoryArgs = {
    block?: InputMaybe<Block_Height>
    id: Scalars['ID']['input']
    subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionFarmingPoolArgs = {
    block?: InputMaybe<Block_Height>
    id: Scalars['ID']['input']
    subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionFarmingPoolsArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<FarmingPool_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    where?: InputMaybe<FarmingPool_Filter>
}

export type SubscriptionFarmingTransactionArgs = {
    block?: InputMaybe<Block_Height>
    id: Scalars['ID']['input']
    subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionFarmingTransactionsArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<FarmingTransaction_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    where?: InputMaybe<FarmingTransaction_Filter>
}

export type SubscriptionFarmingUserArgs = {
    block?: InputMaybe<Block_Height>
    id: Scalars['ID']['input']
    subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionFarmingUsersArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<FarmingUser_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    where?: InputMaybe<FarmingUser_Filter>
}

export type SubscriptionMintArgs = {
    block?: InputMaybe<Block_Height>
    id: Scalars['ID']['input']
    subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionMintsArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Mint_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    where?: InputMaybe<Mint_Filter>
}

export type SubscriptionPairArgs = {
    block?: InputMaybe<Block_Height>
    id: Scalars['ID']['input']
    subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionPairDayDataArgs = {
    block?: InputMaybe<Block_Height>
    id: Scalars['ID']['input']
    subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionPairDayDatasArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<PairDayData_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    where?: InputMaybe<PairDayData_Filter>
}

export type SubscriptionPairHourDataArgs = {
    block?: InputMaybe<Block_Height>
    id: Scalars['ID']['input']
    subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionPairHourDatasArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<PairHourData_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    where?: InputMaybe<PairHourData_Filter>
}

export type SubscriptionPairsArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Pair_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    where?: InputMaybe<Pair_Filter>
}

export type SubscriptionSwapArgs = {
    block?: InputMaybe<Block_Height>
    id: Scalars['ID']['input']
    subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionSwapsArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Swap_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    where?: InputMaybe<Swap_Filter>
}

export type SubscriptionTokenArgs = {
    block?: InputMaybe<Block_Height>
    id: Scalars['ID']['input']
    subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionTokenDayDataArgs = {
    block?: InputMaybe<Block_Height>
    id: Scalars['ID']['input']
    subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionTokenDayDatasArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<TokenDayData_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    where?: InputMaybe<TokenDayData_Filter>
}

export type SubscriptionTokensArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Token_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    where?: InputMaybe<Token_Filter>
}

export type SubscriptionTransactionArgs = {
    block?: InputMaybe<Block_Height>
    id: Scalars['ID']['input']
    subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionTransactionsArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Transaction_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    where?: InputMaybe<Transaction_Filter>
}

export type SubscriptionUserArgs = {
    block?: InputMaybe<Block_Height>
    id: Scalars['ID']['input']
    subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionUserLiquiditiesArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<UserLiquidity_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    where?: InputMaybe<UserLiquidity_Filter>
}

export type SubscriptionUserLiquidityArgs = {
    block?: InputMaybe<Block_Height>
    id: Scalars['ID']['input']
    subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionUserSwapDayDataArgs = {
    block?: InputMaybe<Block_Height>
    id: Scalars['ID']['input']
    subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionUserSwapDayDatasArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<UserSwapDayData_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    where?: InputMaybe<UserSwapDayData_Filter>
}

export type SubscriptionUsersArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<User_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    where?: InputMaybe<User_Filter>
}

export type SubscriptionXecpRedeemArgs = {
    block?: InputMaybe<Block_Height>
    id: Scalars['ID']['input']
    subgraphError?: _SubgraphErrorPolicy_
}

export type SubscriptionXecpRedeemsArgs = {
    block?: InputMaybe<Block_Height>
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<XecpRedeem_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    subgraphError?: _SubgraphErrorPolicy_
    where?: InputMaybe<XecpRedeem_Filter>
}

export type Swap = {
    __typename?: 'Swap'
    amount0In: Scalars['BigDecimal']['output']
    amount0Out: Scalars['BigDecimal']['output']
    amount1In: Scalars['BigDecimal']['output']
    amount1Out: Scalars['BigDecimal']['output']
    amountTokenFee: Scalars['BigDecimal']['output']
    amountTokenReward: Scalars['BigDecimal']['output']
    amountUSD: Scalars['BigDecimal']['output']
    from: Scalars['Bytes']['output']
    id: Scalars['ID']['output']
    logIndex?: Maybe<Scalars['BigInt']['output']>
    pair: Pair
    sender: Scalars['Bytes']['output']
    timestamp: Scalars['BigInt']['output']
    to: Scalars['Bytes']['output']
    transaction: Transaction
}

export type Swap_Filter = {
    /** Filter for the block changed event. */
    _change_block?: InputMaybe<BlockChangedFilter>
    amount0In?: InputMaybe<Scalars['BigDecimal']['input']>
    amount0In_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    amount0In_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    amount0In_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    amount0In_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    amount0In_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    amount0In_not?: InputMaybe<Scalars['BigDecimal']['input']>
    amount0In_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    amount0Out?: InputMaybe<Scalars['BigDecimal']['input']>
    amount0Out_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    amount0Out_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    amount0Out_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    amount0Out_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    amount0Out_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    amount0Out_not?: InputMaybe<Scalars['BigDecimal']['input']>
    amount0Out_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    amount1In?: InputMaybe<Scalars['BigDecimal']['input']>
    amount1In_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    amount1In_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    amount1In_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    amount1In_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    amount1In_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    amount1In_not?: InputMaybe<Scalars['BigDecimal']['input']>
    amount1In_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    amount1Out?: InputMaybe<Scalars['BigDecimal']['input']>
    amount1Out_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    amount1Out_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    amount1Out_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    amount1Out_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    amount1Out_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    amount1Out_not?: InputMaybe<Scalars['BigDecimal']['input']>
    amount1Out_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    amountTokenFee?: InputMaybe<Scalars['BigDecimal']['input']>
    amountTokenFee_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    amountTokenFee_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    amountTokenFee_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    amountTokenFee_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    amountTokenFee_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    amountTokenFee_not?: InputMaybe<Scalars['BigDecimal']['input']>
    amountTokenFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    amountTokenReward?: InputMaybe<Scalars['BigDecimal']['input']>
    amountTokenReward_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    amountTokenReward_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    amountTokenReward_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    amountTokenReward_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    amountTokenReward_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    amountTokenReward_not?: InputMaybe<Scalars['BigDecimal']['input']>
    amountTokenReward_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    amountUSD?: InputMaybe<Scalars['BigDecimal']['input']>
    amountUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    amountUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    amountUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    amountUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    amountUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    amountUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>
    amountUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    and?: InputMaybe<Array<InputMaybe<Swap_Filter>>>
    from?: InputMaybe<Scalars['Bytes']['input']>
    from_contains?: InputMaybe<Scalars['Bytes']['input']>
    from_gt?: InputMaybe<Scalars['Bytes']['input']>
    from_gte?: InputMaybe<Scalars['Bytes']['input']>
    from_in?: InputMaybe<Array<Scalars['Bytes']['input']>>
    from_lt?: InputMaybe<Scalars['Bytes']['input']>
    from_lte?: InputMaybe<Scalars['Bytes']['input']>
    from_not?: InputMaybe<Scalars['Bytes']['input']>
    from_not_contains?: InputMaybe<Scalars['Bytes']['input']>
    from_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_gt?: InputMaybe<Scalars['ID']['input']>
    id_gte?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<Scalars['ID']['input']>>
    id_lt?: InputMaybe<Scalars['ID']['input']>
    id_lte?: InputMaybe<Scalars['ID']['input']>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>
    logIndex?: InputMaybe<Scalars['BigInt']['input']>
    logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>
    logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>
    logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>
    logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>
    logIndex_not?: InputMaybe<Scalars['BigInt']['input']>
    logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    or?: InputMaybe<Array<InputMaybe<Swap_Filter>>>
    pair?: InputMaybe<Scalars['String']['input']>
    pair_?: InputMaybe<Pair_Filter>
    pair_contains?: InputMaybe<Scalars['String']['input']>
    pair_contains_nocase?: InputMaybe<Scalars['String']['input']>
    pair_ends_with?: InputMaybe<Scalars['String']['input']>
    pair_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    pair_gt?: InputMaybe<Scalars['String']['input']>
    pair_gte?: InputMaybe<Scalars['String']['input']>
    pair_in?: InputMaybe<Array<Scalars['String']['input']>>
    pair_lt?: InputMaybe<Scalars['String']['input']>
    pair_lte?: InputMaybe<Scalars['String']['input']>
    pair_not?: InputMaybe<Scalars['String']['input']>
    pair_not_contains?: InputMaybe<Scalars['String']['input']>
    pair_not_contains_nocase?: InputMaybe<Scalars['String']['input']>
    pair_not_ends_with?: InputMaybe<Scalars['String']['input']>
    pair_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    pair_not_in?: InputMaybe<Array<Scalars['String']['input']>>
    pair_not_starts_with?: InputMaybe<Scalars['String']['input']>
    pair_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    pair_starts_with?: InputMaybe<Scalars['String']['input']>
    pair_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['Bytes']['input']>
    sender_contains?: InputMaybe<Scalars['Bytes']['input']>
    sender_gt?: InputMaybe<Scalars['Bytes']['input']>
    sender_gte?: InputMaybe<Scalars['Bytes']['input']>
    sender_in?: InputMaybe<Array<Scalars['Bytes']['input']>>
    sender_lt?: InputMaybe<Scalars['Bytes']['input']>
    sender_lte?: InputMaybe<Scalars['Bytes']['input']>
    sender_not?: InputMaybe<Scalars['Bytes']['input']>
    sender_not_contains?: InputMaybe<Scalars['Bytes']['input']>
    sender_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>
    timestamp?: InputMaybe<Scalars['BigInt']['input']>
    timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>
    timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>
    timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>
    timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>
    timestamp_not?: InputMaybe<Scalars['BigInt']['input']>
    timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    to?: InputMaybe<Scalars['Bytes']['input']>
    to_contains?: InputMaybe<Scalars['Bytes']['input']>
    to_gt?: InputMaybe<Scalars['Bytes']['input']>
    to_gte?: InputMaybe<Scalars['Bytes']['input']>
    to_in?: InputMaybe<Array<Scalars['Bytes']['input']>>
    to_lt?: InputMaybe<Scalars['Bytes']['input']>
    to_lte?: InputMaybe<Scalars['Bytes']['input']>
    to_not?: InputMaybe<Scalars['Bytes']['input']>
    to_not_contains?: InputMaybe<Scalars['Bytes']['input']>
    to_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>
    transaction?: InputMaybe<Scalars['String']['input']>
    transaction_?: InputMaybe<Transaction_Filter>
    transaction_contains?: InputMaybe<Scalars['String']['input']>
    transaction_contains_nocase?: InputMaybe<Scalars['String']['input']>
    transaction_ends_with?: InputMaybe<Scalars['String']['input']>
    transaction_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    transaction_gt?: InputMaybe<Scalars['String']['input']>
    transaction_gte?: InputMaybe<Scalars['String']['input']>
    transaction_in?: InputMaybe<Array<Scalars['String']['input']>>
    transaction_lt?: InputMaybe<Scalars['String']['input']>
    transaction_lte?: InputMaybe<Scalars['String']['input']>
    transaction_not?: InputMaybe<Scalars['String']['input']>
    transaction_not_contains?: InputMaybe<Scalars['String']['input']>
    transaction_not_contains_nocase?: InputMaybe<Scalars['String']['input']>
    transaction_not_ends_with?: InputMaybe<Scalars['String']['input']>
    transaction_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    transaction_not_in?: InputMaybe<Array<Scalars['String']['input']>>
    transaction_not_starts_with?: InputMaybe<Scalars['String']['input']>
    transaction_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    transaction_starts_with?: InputMaybe<Scalars['String']['input']>
    transaction_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
}

export enum Swap_OrderBy {
    Amount0In = 'amount0In',
    Amount0Out = 'amount0Out',
    Amount1In = 'amount1In',
    Amount1Out = 'amount1Out',
    AmountTokenFee = 'amountTokenFee',
    AmountTokenReward = 'amountTokenReward',
    AmountUsd = 'amountUSD',
    From = 'from',
    Id = 'id',
    LogIndex = 'logIndex',
    Pair = 'pair',
    PairBlock = 'pair__block',
    PairCurrentFee = 'pair__currentFee',
    PairId = 'pair__id',
    PairLpTokenPrice = 'pair__lpTokenPrice',
    PairName = 'pair__name',
    PairReserve0 = 'pair__reserve0',
    PairReserve1 = 'pair__reserve1',
    PairReserveEth = 'pair__reserveETH',
    PairReserveUsd = 'pair__reserveUSD',
    PairTimestamp = 'pair__timestamp',
    PairToken0Price = 'pair__token0Price',
    PairToken1Price = 'pair__token1Price',
    PairTotalFee = 'pair__totalFee',
    PairTotalSupply = 'pair__totalSupply',
    PairTotalTransactions = 'pair__totalTransactions',
    PairTrackedReserveEth = 'pair__trackedReserveETH',
    PairUntrackedVolumeUsd = 'pair__untrackedVolumeUSD',
    PairVolumeToken0 = 'pair__volumeToken0',
    PairVolumeToken1 = 'pair__volumeToken1',
    PairVolumeUsd = 'pair__volumeUSD',
    Sender = 'sender',
    Timestamp = 'timestamp',
    To = 'to',
    Transaction = 'transaction',
    TransactionBlock = 'transaction__block',
    TransactionId = 'transaction__id',
    TransactionTimestamp = 'transaction__timestamp',
}

export type Token = {
    __typename?: 'Token'
    /** Decimals */
    decimals: Scalars['BigInt']['output']
    derivedETH?: Maybe<Scalars['BigDecimal']['output']>
    derivedUSD?: Maybe<Scalars['BigDecimal']['output']>
    id: Scalars['ID']['output']
    /** Name */
    name: Scalars['String']['output']
    pairBase: Array<Pair>
    pairDayDataBase: Array<PairDayData>
    pairDayDataQuote: Array<PairDayData>
    pairQuote: Array<Pair>
    /** Symbol */
    symbol: Scalars['String']['output']
    tokenDayData: Array<TokenDayData>
    totalLiquidity: Scalars['BigDecimal']['output']
    totalTransactions: Scalars['BigInt']['output']
    tradeVolume: Scalars['BigDecimal']['output']
    tradeVolumeUSD: Scalars['BigDecimal']['output']
    untrackedVolumeUSD: Scalars['BigDecimal']['output']
}

export type TokenPairBaseArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Pair_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    where?: InputMaybe<Pair_Filter>
}

export type TokenPairDayDataBaseArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<PairDayData_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    where?: InputMaybe<PairDayData_Filter>
}

export type TokenPairDayDataQuoteArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<PairDayData_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    where?: InputMaybe<PairDayData_Filter>
}

export type TokenPairQuoteArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Pair_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    where?: InputMaybe<Pair_Filter>
}

export type TokenTokenDayDataArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<TokenDayData_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    where?: InputMaybe<TokenDayData_Filter>
}

export type TokenDayData = {
    __typename?: 'TokenDayData'
    dailyTxns: Scalars['BigInt']['output']
    dailyVolumeETH: Scalars['BigDecimal']['output']
    dailyVolumeToken: Scalars['BigDecimal']['output']
    dailyVolumeUSD: Scalars['BigDecimal']['output']
    date: Scalars['Int']['output']
    id: Scalars['ID']['output']
    priceUSD: Scalars['BigDecimal']['output']
    token: Token
    totalLiquidityETH: Scalars['BigDecimal']['output']
    totalLiquidityToken: Scalars['BigDecimal']['output']
    totalLiquidityUSD: Scalars['BigDecimal']['output']
}

export type TokenDayData_Filter = {
    /** Filter for the block changed event. */
    _change_block?: InputMaybe<BlockChangedFilter>
    and?: InputMaybe<Array<InputMaybe<TokenDayData_Filter>>>
    dailyTxns?: InputMaybe<Scalars['BigInt']['input']>
    dailyTxns_gt?: InputMaybe<Scalars['BigInt']['input']>
    dailyTxns_gte?: InputMaybe<Scalars['BigInt']['input']>
    dailyTxns_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    dailyTxns_lt?: InputMaybe<Scalars['BigInt']['input']>
    dailyTxns_lte?: InputMaybe<Scalars['BigInt']['input']>
    dailyTxns_not?: InputMaybe<Scalars['BigInt']['input']>
    dailyTxns_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    dailyVolumeETH?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeETH_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeETH_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeETH_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    dailyVolumeETH_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeETH_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeETH_not?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeETH_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    dailyVolumeToken?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeToken_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeToken_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeToken_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    dailyVolumeToken_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeToken_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeToken_not?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeToken_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    dailyVolumeUSD?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    dailyVolumeUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>
    dailyVolumeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    date?: InputMaybe<Scalars['Int']['input']>
    date_gt?: InputMaybe<Scalars['Int']['input']>
    date_gte?: InputMaybe<Scalars['Int']['input']>
    date_in?: InputMaybe<Array<Scalars['Int']['input']>>
    date_lt?: InputMaybe<Scalars['Int']['input']>
    date_lte?: InputMaybe<Scalars['Int']['input']>
    date_not?: InputMaybe<Scalars['Int']['input']>
    date_not_in?: InputMaybe<Array<Scalars['Int']['input']>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_gt?: InputMaybe<Scalars['ID']['input']>
    id_gte?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<Scalars['ID']['input']>>
    id_lt?: InputMaybe<Scalars['ID']['input']>
    id_lte?: InputMaybe<Scalars['ID']['input']>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>
    or?: InputMaybe<Array<InputMaybe<TokenDayData_Filter>>>
    priceUSD?: InputMaybe<Scalars['BigDecimal']['input']>
    priceUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    priceUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    priceUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    priceUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    priceUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    priceUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>
    priceUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    token?: InputMaybe<Scalars['String']['input']>
    token_?: InputMaybe<Token_Filter>
    token_contains?: InputMaybe<Scalars['String']['input']>
    token_contains_nocase?: InputMaybe<Scalars['String']['input']>
    token_ends_with?: InputMaybe<Scalars['String']['input']>
    token_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    token_gt?: InputMaybe<Scalars['String']['input']>
    token_gte?: InputMaybe<Scalars['String']['input']>
    token_in?: InputMaybe<Array<Scalars['String']['input']>>
    token_lt?: InputMaybe<Scalars['String']['input']>
    token_lte?: InputMaybe<Scalars['String']['input']>
    token_not?: InputMaybe<Scalars['String']['input']>
    token_not_contains?: InputMaybe<Scalars['String']['input']>
    token_not_contains_nocase?: InputMaybe<Scalars['String']['input']>
    token_not_ends_with?: InputMaybe<Scalars['String']['input']>
    token_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    token_not_in?: InputMaybe<Array<Scalars['String']['input']>>
    token_not_starts_with?: InputMaybe<Scalars['String']['input']>
    token_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    token_starts_with?: InputMaybe<Scalars['String']['input']>
    token_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    totalLiquidityETH?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityETH_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityETH_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityETH_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    totalLiquidityETH_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityETH_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityETH_not?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityETH_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    totalLiquidityToken?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityToken_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityToken_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityToken_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    totalLiquidityToken_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityToken_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityToken_not?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityToken_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    totalLiquidityUSD?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    totalLiquidityUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidityUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
}

export enum TokenDayData_OrderBy {
    DailyTxns = 'dailyTxns',
    DailyVolumeEth = 'dailyVolumeETH',
    DailyVolumeToken = 'dailyVolumeToken',
    DailyVolumeUsd = 'dailyVolumeUSD',
    Date = 'date',
    Id = 'id',
    PriceUsd = 'priceUSD',
    Token = 'token',
    TokenDecimals = 'token__decimals',
    TokenDerivedEth = 'token__derivedETH',
    TokenDerivedUsd = 'token__derivedUSD',
    TokenId = 'token__id',
    TokenName = 'token__name',
    TokenSymbol = 'token__symbol',
    TokenTotalLiquidity = 'token__totalLiquidity',
    TokenTotalTransactions = 'token__totalTransactions',
    TokenTradeVolume = 'token__tradeVolume',
    TokenTradeVolumeUsd = 'token__tradeVolumeUSD',
    TokenUntrackedVolumeUsd = 'token__untrackedVolumeUSD',
    TotalLiquidityEth = 'totalLiquidityETH',
    TotalLiquidityToken = 'totalLiquidityToken',
    TotalLiquidityUsd = 'totalLiquidityUSD',
}

export type Token_Filter = {
    /** Filter for the block changed event. */
    _change_block?: InputMaybe<BlockChangedFilter>
    and?: InputMaybe<Array<InputMaybe<Token_Filter>>>
    decimals?: InputMaybe<Scalars['BigInt']['input']>
    decimals_gt?: InputMaybe<Scalars['BigInt']['input']>
    decimals_gte?: InputMaybe<Scalars['BigInt']['input']>
    decimals_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    decimals_lt?: InputMaybe<Scalars['BigInt']['input']>
    decimals_lte?: InputMaybe<Scalars['BigInt']['input']>
    decimals_not?: InputMaybe<Scalars['BigInt']['input']>
    decimals_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    derivedETH?: InputMaybe<Scalars['BigDecimal']['input']>
    derivedETH_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    derivedETH_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    derivedETH_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    derivedETH_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    derivedETH_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    derivedETH_not?: InputMaybe<Scalars['BigDecimal']['input']>
    derivedETH_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    derivedUSD?: InputMaybe<Scalars['BigDecimal']['input']>
    derivedUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    derivedUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    derivedUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    derivedUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    derivedUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    derivedUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>
    derivedUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_gt?: InputMaybe<Scalars['ID']['input']>
    id_gte?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<Scalars['ID']['input']>>
    id_lt?: InputMaybe<Scalars['ID']['input']>
    id_lte?: InputMaybe<Scalars['ID']['input']>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_nocase?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    name_gt?: InputMaybe<Scalars['String']['input']>
    name_gte?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<Scalars['String']['input']>>
    name_lt?: InputMaybe<Scalars['String']['input']>
    name_lte?: InputMaybe<Scalars['String']['input']>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<Scalars['String']['input']>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    or?: InputMaybe<Array<InputMaybe<Token_Filter>>>
    pairBase_?: InputMaybe<Pair_Filter>
    pairDayDataBase_?: InputMaybe<PairDayData_Filter>
    pairDayDataQuote_?: InputMaybe<PairDayData_Filter>
    pairQuote_?: InputMaybe<Pair_Filter>
    symbol?: InputMaybe<Scalars['String']['input']>
    symbol_contains?: InputMaybe<Scalars['String']['input']>
    symbol_contains_nocase?: InputMaybe<Scalars['String']['input']>
    symbol_ends_with?: InputMaybe<Scalars['String']['input']>
    symbol_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    symbol_gt?: InputMaybe<Scalars['String']['input']>
    symbol_gte?: InputMaybe<Scalars['String']['input']>
    symbol_in?: InputMaybe<Array<Scalars['String']['input']>>
    symbol_lt?: InputMaybe<Scalars['String']['input']>
    symbol_lte?: InputMaybe<Scalars['String']['input']>
    symbol_not?: InputMaybe<Scalars['String']['input']>
    symbol_not_contains?: InputMaybe<Scalars['String']['input']>
    symbol_not_contains_nocase?: InputMaybe<Scalars['String']['input']>
    symbol_not_ends_with?: InputMaybe<Scalars['String']['input']>
    symbol_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    symbol_not_in?: InputMaybe<Array<Scalars['String']['input']>>
    symbol_not_starts_with?: InputMaybe<Scalars['String']['input']>
    symbol_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    symbol_starts_with?: InputMaybe<Scalars['String']['input']>
    symbol_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    tokenDayData_?: InputMaybe<TokenDayData_Filter>
    totalLiquidity?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidity_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidity_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidity_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    totalLiquidity_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidity_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidity_not?: InputMaybe<Scalars['BigDecimal']['input']>
    totalLiquidity_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    totalTransactions?: InputMaybe<Scalars['BigInt']['input']>
    totalTransactions_gt?: InputMaybe<Scalars['BigInt']['input']>
    totalTransactions_gte?: InputMaybe<Scalars['BigInt']['input']>
    totalTransactions_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    totalTransactions_lt?: InputMaybe<Scalars['BigInt']['input']>
    totalTransactions_lte?: InputMaybe<Scalars['BigInt']['input']>
    totalTransactions_not?: InputMaybe<Scalars['BigInt']['input']>
    totalTransactions_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    tradeVolume?: InputMaybe<Scalars['BigDecimal']['input']>
    tradeVolumeUSD?: InputMaybe<Scalars['BigDecimal']['input']>
    tradeVolumeUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    tradeVolumeUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    tradeVolumeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    tradeVolumeUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    tradeVolumeUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    tradeVolumeUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>
    tradeVolumeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    tradeVolume_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    tradeVolume_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    tradeVolume_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    tradeVolume_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    tradeVolume_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    tradeVolume_not?: InputMaybe<Scalars['BigDecimal']['input']>
    tradeVolume_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    untrackedVolumeUSD?: InputMaybe<Scalars['BigDecimal']['input']>
    untrackedVolumeUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    untrackedVolumeUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    untrackedVolumeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    untrackedVolumeUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    untrackedVolumeUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    untrackedVolumeUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>
    untrackedVolumeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
}

export enum Token_OrderBy {
    Decimals = 'decimals',
    DerivedEth = 'derivedETH',
    DerivedUsd = 'derivedUSD',
    Id = 'id',
    Name = 'name',
    PairBase = 'pairBase',
    PairDayDataBase = 'pairDayDataBase',
    PairDayDataQuote = 'pairDayDataQuote',
    PairQuote = 'pairQuote',
    Symbol = 'symbol',
    TokenDayData = 'tokenDayData',
    TotalLiquidity = 'totalLiquidity',
    TotalTransactions = 'totalTransactions',
    TradeVolume = 'tradeVolume',
    TradeVolumeUsd = 'tradeVolumeUSD',
    UntrackedVolumeUsd = 'untrackedVolumeUSD',
}

export type Transaction = {
    __typename?: 'Transaction'
    block: Scalars['BigInt']['output']
    burns: Array<Burn>
    id: Scalars['ID']['output']
    mints: Array<Mint>
    swaps: Array<Swap>
    timestamp: Scalars['BigInt']['output']
}

export type TransactionBurnsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Burn_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    where?: InputMaybe<Burn_Filter>
}

export type TransactionMintsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Mint_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    where?: InputMaybe<Mint_Filter>
}

export type TransactionSwapsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Swap_OrderBy>
    orderDirection?: InputMaybe<OrderDirection>
    skip?: InputMaybe<Scalars['Int']['input']>
    where?: InputMaybe<Swap_Filter>
}

export type Transaction_Filter = {
    /** Filter for the block changed event. */
    _change_block?: InputMaybe<BlockChangedFilter>
    and?: InputMaybe<Array<InputMaybe<Transaction_Filter>>>
    block?: InputMaybe<Scalars['BigInt']['input']>
    block_gt?: InputMaybe<Scalars['BigInt']['input']>
    block_gte?: InputMaybe<Scalars['BigInt']['input']>
    block_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    block_lt?: InputMaybe<Scalars['BigInt']['input']>
    block_lte?: InputMaybe<Scalars['BigInt']['input']>
    block_not?: InputMaybe<Scalars['BigInt']['input']>
    block_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    burns?: InputMaybe<Array<Scalars['String']['input']>>
    burns_?: InputMaybe<Burn_Filter>
    burns_contains?: InputMaybe<Array<Scalars['String']['input']>>
    burns_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>
    burns_not?: InputMaybe<Array<Scalars['String']['input']>>
    burns_not_contains?: InputMaybe<Array<Scalars['String']['input']>>
    burns_not_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_gt?: InputMaybe<Scalars['ID']['input']>
    id_gte?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<Scalars['ID']['input']>>
    id_lt?: InputMaybe<Scalars['ID']['input']>
    id_lte?: InputMaybe<Scalars['ID']['input']>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>
    mints?: InputMaybe<Array<Scalars['String']['input']>>
    mints_?: InputMaybe<Mint_Filter>
    mints_contains?: InputMaybe<Array<Scalars['String']['input']>>
    mints_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>
    mints_not?: InputMaybe<Array<Scalars['String']['input']>>
    mints_not_contains?: InputMaybe<Array<Scalars['String']['input']>>
    mints_not_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>
    or?: InputMaybe<Array<InputMaybe<Transaction_Filter>>>
    swaps?: InputMaybe<Array<Scalars['String']['input']>>
    swaps_?: InputMaybe<Swap_Filter>
    swaps_contains?: InputMaybe<Array<Scalars['String']['input']>>
    swaps_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>
    swaps_not?: InputMaybe<Array<Scalars['String']['input']>>
    swaps_not_contains?: InputMaybe<Array<Scalars['String']['input']>>
    swaps_not_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>
    timestamp?: InputMaybe<Scalars['BigInt']['input']>
    timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>
    timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>
    timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
    timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>
    timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>
    timestamp_not?: InputMaybe<Scalars['BigInt']['input']>
    timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>
}

export enum Transaction_OrderBy {
    Block = 'block',
    Burns = 'burns',
    Id = 'id',
    Mints = 'mints',
    Swaps = 'swaps',
    Timestamp = 'timestamp',
}

export type User = {
    __typename?: 'User'
    id: Scalars['ID']['output']
}

export type UserLiquidity = {
    __typename?: 'UserLiquidity'
    address: Scalars['Bytes']['output']
    id: Scalars['ID']['output']
    liquidity: Scalars['BigDecimal']['output']
    pair: Pair
}

export type UserLiquidity_Filter = {
    /** Filter for the block changed event. */
    _change_block?: InputMaybe<BlockChangedFilter>
    address?: InputMaybe<Scalars['Bytes']['input']>
    address_contains?: InputMaybe<Scalars['Bytes']['input']>
    address_gt?: InputMaybe<Scalars['Bytes']['input']>
    address_gte?: InputMaybe<Scalars['Bytes']['input']>
    address_in?: InputMaybe<Array<Scalars['Bytes']['input']>>
    address_lt?: InputMaybe<Scalars['Bytes']['input']>
    address_lte?: InputMaybe<Scalars['Bytes']['input']>
    address_not?: InputMaybe<Scalars['Bytes']['input']>
    address_not_contains?: InputMaybe<Scalars['Bytes']['input']>
    address_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>
    and?: InputMaybe<Array<InputMaybe<UserLiquidity_Filter>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_gt?: InputMaybe<Scalars['ID']['input']>
    id_gte?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<Scalars['ID']['input']>>
    id_lt?: InputMaybe<Scalars['ID']['input']>
    id_lte?: InputMaybe<Scalars['ID']['input']>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>
    liquidity?: InputMaybe<Scalars['BigDecimal']['input']>
    liquidity_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    liquidity_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    liquidity_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    liquidity_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    liquidity_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    liquidity_not?: InputMaybe<Scalars['BigDecimal']['input']>
    liquidity_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    or?: InputMaybe<Array<InputMaybe<UserLiquidity_Filter>>>
    pair?: InputMaybe<Scalars['String']['input']>
    pair_?: InputMaybe<Pair_Filter>
    pair_contains?: InputMaybe<Scalars['String']['input']>
    pair_contains_nocase?: InputMaybe<Scalars['String']['input']>
    pair_ends_with?: InputMaybe<Scalars['String']['input']>
    pair_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    pair_gt?: InputMaybe<Scalars['String']['input']>
    pair_gte?: InputMaybe<Scalars['String']['input']>
    pair_in?: InputMaybe<Array<Scalars['String']['input']>>
    pair_lt?: InputMaybe<Scalars['String']['input']>
    pair_lte?: InputMaybe<Scalars['String']['input']>
    pair_not?: InputMaybe<Scalars['String']['input']>
    pair_not_contains?: InputMaybe<Scalars['String']['input']>
    pair_not_contains_nocase?: InputMaybe<Scalars['String']['input']>
    pair_not_ends_with?: InputMaybe<Scalars['String']['input']>
    pair_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>
    pair_not_in?: InputMaybe<Array<Scalars['String']['input']>>
    pair_not_starts_with?: InputMaybe<Scalars['String']['input']>
    pair_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
    pair_starts_with?: InputMaybe<Scalars['String']['input']>
    pair_starts_with_nocase?: InputMaybe<Scalars['String']['input']>
}

export enum UserLiquidity_OrderBy {
    Address = 'address',
    Id = 'id',
    Liquidity = 'liquidity',
    Pair = 'pair',
    PairBlock = 'pair__block',
    PairCurrentFee = 'pair__currentFee',
    PairId = 'pair__id',
    PairLpTokenPrice = 'pair__lpTokenPrice',
    PairName = 'pair__name',
    PairReserve0 = 'pair__reserve0',
    PairReserve1 = 'pair__reserve1',
    PairReserveEth = 'pair__reserveETH',
    PairReserveUsd = 'pair__reserveUSD',
    PairTimestamp = 'pair__timestamp',
    PairToken0Price = 'pair__token0Price',
    PairToken1Price = 'pair__token1Price',
    PairTotalFee = 'pair__totalFee',
    PairTotalSupply = 'pair__totalSupply',
    PairTotalTransactions = 'pair__totalTransactions',
    PairTrackedReserveEth = 'pair__trackedReserveETH',
    PairUntrackedVolumeUsd = 'pair__untrackedVolumeUSD',
    PairVolumeToken0 = 'pair__volumeToken0',
    PairVolumeToken1 = 'pair__volumeToken1',
    PairVolumeUsd = 'pair__volumeUSD',
}

export type UserSwapDayData = {
    __typename?: 'UserSwapDayData'
    id: Scalars['ID']['output']
    swapVolumeUSD: Scalars['BigDecimal']['output']
}

export type UserSwapDayData_Filter = {
    /** Filter for the block changed event. */
    _change_block?: InputMaybe<BlockChangedFilter>
    and?: InputMaybe<Array<InputMaybe<UserSwapDayData_Filter>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_gt?: InputMaybe<Scalars['ID']['input']>
    id_gte?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<Scalars['ID']['input']>>
    id_lt?: InputMaybe<Scalars['ID']['input']>
    id_lte?: InputMaybe<Scalars['ID']['input']>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>
    or?: InputMaybe<Array<InputMaybe<UserSwapDayData_Filter>>>
    swapVolumeUSD?: InputMaybe<Scalars['BigDecimal']['input']>
    swapVolumeUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    swapVolumeUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    swapVolumeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    swapVolumeUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    swapVolumeUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    swapVolumeUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>
    swapVolumeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
}

export enum UserSwapDayData_OrderBy {
    Id = 'id',
    SwapVolumeUsd = 'swapVolumeUSD',
}

export type User_Filter = {
    /** Filter for the block changed event. */
    _change_block?: InputMaybe<BlockChangedFilter>
    and?: InputMaybe<Array<InputMaybe<User_Filter>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_gt?: InputMaybe<Scalars['ID']['input']>
    id_gte?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<Scalars['ID']['input']>>
    id_lt?: InputMaybe<Scalars['ID']['input']>
    id_lte?: InputMaybe<Scalars['ID']['input']>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>
    or?: InputMaybe<Array<InputMaybe<User_Filter>>>
}

export enum User_OrderBy {
    Id = 'id',
}

export type _Block_ = {
    __typename?: '_Block_'
    /** The hash of the block */
    hash?: Maybe<Scalars['Bytes']['output']>
    /** The block number */
    number: Scalars['Int']['output']
    /** Integer representation of the timestamp stored in blocks for the chain */
    timestamp?: Maybe<Scalars['Int']['output']>
}

/** The type for the top-level _meta field */
export type _Meta_ = {
    __typename?: '_Meta_'
    /**
     * Information about a specific subgraph block. The hash of the block
     * will be null if the _meta field has a block constraint that asks for
     * a block number. It will be filled if the _meta field has no block constraint
     * and therefore asks for the latest  block
     *
     */
    block: _Block_
    /** The deployment ID */
    deployment: Scalars['String']['output']
    /** If `true`, the subgraph encountered indexing errors at some past block */
    hasIndexingErrors: Scalars['Boolean']['output']
}

export enum _SubgraphErrorPolicy_ {
    /** Data will be returned even if the subgraph has indexing errors */
    Allow = 'allow',
    /** If the subgraph has indexing errors, data will be omitted. The default. */
    Deny = 'deny',
}

export type XecpRedeem = {
    __typename?: 'xecpRedeem'
    ECP?: Maybe<Scalars['BigDecimal']['output']>
    address: Scalars['Bytes']['output']
    canClaim: Scalars['BigDecimal']['output']
    endTime: Scalars['Int']['output']
    id: Scalars['ID']['output']
    index: Scalars['BigDecimal']['output']
    startTime: Scalars['Int']['output']
    xECP: Scalars['BigDecimal']['output']
}

export type XecpRedeem_Filter = {
    ECP?: InputMaybe<Scalars['BigDecimal']['input']>
    ECP_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    ECP_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    ECP_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    ECP_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    ECP_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    ECP_not?: InputMaybe<Scalars['BigDecimal']['input']>
    ECP_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    /** Filter for the block changed event. */
    _change_block?: InputMaybe<BlockChangedFilter>
    address?: InputMaybe<Scalars['Bytes']['input']>
    address_contains?: InputMaybe<Scalars['Bytes']['input']>
    address_gt?: InputMaybe<Scalars['Bytes']['input']>
    address_gte?: InputMaybe<Scalars['Bytes']['input']>
    address_in?: InputMaybe<Array<Scalars['Bytes']['input']>>
    address_lt?: InputMaybe<Scalars['Bytes']['input']>
    address_lte?: InputMaybe<Scalars['Bytes']['input']>
    address_not?: InputMaybe<Scalars['Bytes']['input']>
    address_not_contains?: InputMaybe<Scalars['Bytes']['input']>
    address_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>
    and?: InputMaybe<Array<InputMaybe<XecpRedeem_Filter>>>
    canClaim?: InputMaybe<Scalars['BigDecimal']['input']>
    canClaim_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    canClaim_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    canClaim_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    canClaim_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    canClaim_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    canClaim_not?: InputMaybe<Scalars['BigDecimal']['input']>
    canClaim_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    endTime?: InputMaybe<Scalars['Int']['input']>
    endTime_gt?: InputMaybe<Scalars['Int']['input']>
    endTime_gte?: InputMaybe<Scalars['Int']['input']>
    endTime_in?: InputMaybe<Array<Scalars['Int']['input']>>
    endTime_lt?: InputMaybe<Scalars['Int']['input']>
    endTime_lte?: InputMaybe<Scalars['Int']['input']>
    endTime_not?: InputMaybe<Scalars['Int']['input']>
    endTime_not_in?: InputMaybe<Array<Scalars['Int']['input']>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_gt?: InputMaybe<Scalars['ID']['input']>
    id_gte?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<Scalars['ID']['input']>>
    id_lt?: InputMaybe<Scalars['ID']['input']>
    id_lte?: InputMaybe<Scalars['ID']['input']>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>
    index?: InputMaybe<Scalars['BigDecimal']['input']>
    index_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    index_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    index_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    index_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    index_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    index_not?: InputMaybe<Scalars['BigDecimal']['input']>
    index_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    or?: InputMaybe<Array<InputMaybe<XecpRedeem_Filter>>>
    startTime?: InputMaybe<Scalars['Int']['input']>
    startTime_gt?: InputMaybe<Scalars['Int']['input']>
    startTime_gte?: InputMaybe<Scalars['Int']['input']>
    startTime_in?: InputMaybe<Array<Scalars['Int']['input']>>
    startTime_lt?: InputMaybe<Scalars['Int']['input']>
    startTime_lte?: InputMaybe<Scalars['Int']['input']>
    startTime_not?: InputMaybe<Scalars['Int']['input']>
    startTime_not_in?: InputMaybe<Array<Scalars['Int']['input']>>
    xECP?: InputMaybe<Scalars['BigDecimal']['input']>
    xECP_gt?: InputMaybe<Scalars['BigDecimal']['input']>
    xECP_gte?: InputMaybe<Scalars['BigDecimal']['input']>
    xECP_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
    xECP_lt?: InputMaybe<Scalars['BigDecimal']['input']>
    xECP_lte?: InputMaybe<Scalars['BigDecimal']['input']>
    xECP_not?: InputMaybe<Scalars['BigDecimal']['input']>
    xECP_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>
}

export enum XecpRedeem_OrderBy {
    Ecp = 'ECP',
    Address = 'address',
    CanClaim = 'canClaim',
    EndTime = 'endTime',
    Id = 'id',
    Index = 'index',
    StartTime = 'startTime',
    XEcp = 'xECP',
}

export type MyPoolFarmCreateQueryVariables = Exact<{
    address: Scalars['String']['input']
}>

export type MyPoolFarmCreateQuery = {
    __typename?: 'Query'
    farmingPools: Array<{
        __typename?: 'FarmingPool'
        id: string
        startDate: any
        endDate: any
        apr: any
        pair: {
            __typename?: 'Pair'
            id: string
            token0: { __typename?: 'Token'; id: string; name: string; decimals: any; symbol: string }
            token1: { __typename?: 'Token'; id: string; name: string; decimals: any; symbol: string }
        }
        tokenReward: { __typename?: 'Token'; symbol: string; name: string; id: string; decimals: any }
    }>
}

export type MyPoolsStakeAvailableQueryVariables = Exact<{
    address: Scalars['Bytes']['input']
}>

export type MyPoolsStakeAvailableQuery = {
    __typename?: 'Query'
    farmingUsers: Array<{
        __typename?: 'FarmingUser'
        amount: any
        harvested: any
        farmingPool: {
            __typename?: 'FarmingPool'
            id: string
            startDate: any
            endDate: any
            apr: any
            totalLP: any
            amountPerSecond: any
            isBlueCheck?: boolean
            pair: {
                __typename?: 'Pair'
                id: string
                token0: { __typename?: 'Token'; id: string; symbol: string; name: string; decimals: any }
                token1: { __typename?: 'Token'; id: string; symbol: string; name: string; decimals: any }
            }
            tokenReward: { __typename?: 'Token'; id: string; name: string; symbol: string; decimals: any }
        }
    }>
}

export type FarmingPoolsAvailableQueryVariables = Exact<{
    now: Scalars['BigDecimal']['input']
    skip?: InputMaybe<Scalars['Int']['input']>
}>

export type FarmingPoolsAvailableQuery = {
    __typename?: 'Query'
    farmingPools: Array<{
        __typename?: 'FarmingPool'
        id: string
        startDate: any
        endDate: any
        apr: any
        totalLP: any
        amountPerSecond: any
        amountReward: any
        harvested: any
        isBlueCheck?: boolean
        pair: {
            __typename?: 'Pair'
            id: string
            token0: { __typename?: 'Token'; id: string; symbol: string; name: string; decimals: any }
            token1: { __typename?: 'Token'; id: string; symbol: string; name: string; decimals: any }
        }
        tokenReward: { __typename?: 'Token'; id: string; name: string; symbol: string; decimals: any }
    }>
}

export type FarmingPoolDetailQueryVariables = Exact<{
    poolId: Scalars['ID']['input']
}>

export type FarmingPoolDetailQuery = {
    __typename?: 'Query'
    farmingPool?: {
        __typename?: 'FarmingPool'
        id: string
        startDate: any
        endDate: any
        apr: any
        totalLP: any
        amountPerSecond: any
        lastRewardTimestamp?: number
        accAmountPerShare?: any
        owner: string
        amountReward: any
        withdraw?: boolean
        pair: {
            __typename?: 'Pair'
            id: string
            reserve0: any
            reserve1: any
            token0Price: any
            token1Price: any
            token0: { __typename?: 'Token'; id: string; symbol: string; name: string; decimals: any; derivedUSD?: any }
            token1: { __typename?: 'Token'; id: string; symbol: string; name: string; decimals: any; derivedUSD?: any }
        }
        tokenReward: { __typename?: 'Token'; id: string; name: string; symbol: string; decimals: any; derivedUSD?: any }
    }
}

export type UserQueryVariables = Exact<{
    userId: Scalars['ID']['input']
}>

export type UserQuery = {
    __typename?: 'Query'
    farmingUser?: { __typename?: 'FarmingUser'; amount: any; rewardEarn: any; rewardDebt: any }
}

export type AvailablePoolsQueryVariables = Exact<{
    skip?: InputMaybe<Scalars['Int']['input']>
}>

export type AvailablePoolsQuery = {
    __typename?: 'Query'
    pairs: Array<{
        __typename?: 'Pair'
        id: string
        reserve0: any
        reserve1: any
        reserveUSD: any
        currentFee: any
        pairHourData: Array<{ __typename?: 'PairHourData'; hourlyVolumeUSD: any }>
        token1: { __typename?: 'Token'; id: string; name: string; decimals: any; symbol: string }
        token0: { __typename?: 'Token'; id: string; name: string; decimals: any; symbol: string }
    }>
}

export type PairDayDataQueryVariables = Exact<{
    id: Scalars['ID']['input']
}>

export type PairDayDataQuery = {
    __typename?: 'Query'
    pair?: {
        __typename?: 'Pair'
        id: string
        token0: { __typename?: 'Token'; id: string }
        token1: { __typename?: 'Token'; id: string }
        pairDayData: Array<{ __typename?: 'PairDayData'; date: number; reserve0: any; reserve1: any }>
    }
}

export type AllPairQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>
}>

export type AllPairQuery = {
    __typename?: 'Query'
    pairs: Array<{
        __typename?: 'Pair'
        id: string
        token1: { __typename?: 'Token'; id: string; name: string; decimals: any; symbol: string }
        token0: { __typename?: 'Token'; id: string; name: string; decimals: any; symbol: string }
    }>
}

export type SearchPairQueryVariables = Exact<{
    query: Scalars['String']['input']
}>

export type SearchPairQuery = {
    __typename?: 'Query'
    pairs: Array<{
        __typename?: 'Pair'
        id: string
        token1: { __typename?: 'Token'; id: string; name: string; decimals: any; symbol: string }
        token0: { __typename?: 'Token'; id: string; name: string; decimals: any; symbol: string }
    }>
}

export type GetTvlQueryVariables = Exact<{ [key: string]: never }>

export type GetTvlQuery = {
    __typename?: 'Query'
    echodexFactories: Array<{
        __typename?: 'EchodexFactory'
        totalLiquidityUSD: any
        totalVolumeUSD: any
        totalTransactions: any
    }>
}

export type GetAirdropDataQueryVariables = Exact<{
    address: Scalars['Bytes']['input']
    addressAndDayId: Scalars['ID']['input']
}>

export type GetAirdropDataQuery = {
    __typename?: 'Query'
    userLiquidities: Array<{
        __typename?: 'UserLiquidity'
        liquidity: any
        pair: { __typename?: 'Pair'; lpTokenPrice: any }
    }>
    userSwapDayData?: { __typename?: 'UserSwapDayData'; swapVolumeUSD: any }
    farmingUsers: Array<{
        __typename?: 'FarmingUser'
        amount: any
        farmingPool: { __typename?: 'FarmingPool'; pair: { __typename?: 'Pair'; lpTokenPrice: any } }
    }>
}

export type GetPriceQueryVariables = Exact<{
    address: Scalars['ID']['input']
}>

export type GetPriceQuery = { __typename?: 'Query'; token?: { __typename?: 'Token'; derivedUSD?: any } }

export type GetTokenDayDataQueryVariables = Exact<{
    address: Scalars['ID']['input']
}>

export type GetTokenDayDataQuery = {
    __typename?: 'Query'
    tokenDayDatas: Array<{ __typename?: 'TokenDayData'; date: number; priceUSD: any }>
}

export type CanClaimQueryVariables = Exact<{
    address: Scalars['Bytes']['input']
    date: Scalars['Int']['input']
}>

export type CanClaimQuery = {
    __typename?: 'Query'
    xecpRedeems: Array<{
        __typename?: 'xecpRedeem'
        id: string
        ECP?: any
        address: any
        endTime: number
        index: any
        startTime: number
        xECP: any
        canClaim: any
    }>
}

export type PendingQueryVariables = Exact<{
    address: Scalars['Bytes']['input']
    date: Scalars['Int']['input']
}>

export type PendingQuery = {
    __typename?: 'Query'
    xecpRedeems: Array<{
        __typename?: 'xecpRedeem'
        id: string
        ECP?: any
        address: any
        endTime: number
        index: any
        startTime: number
        xECP: any
        canClaim: any
    }>
}

export const MyPoolFarmCreateDocument = `
    query myPoolFarmCreate($address: String!) {
  farmingPools(
    where: {owner: $address}
    first: 20
    orderBy: apr
    orderDirection: asc
  ) {
    id
    pair {
      id
      token0 {
        id
        name
        decimals
        symbol
      }
      token1 {
        id
        name
        decimals
        symbol
      }
    }
    tokenReward {
      symbol
      name
      id
      decimals
    }
    startDate
    endDate
    apr
  }
}
    `
export const useMyPoolFarmCreateQuery = <TData = MyPoolFarmCreateQuery, TError = unknown>(
    dataSource: { endpoint: string; fetchParams?: RequestInit },
    variables: MyPoolFarmCreateQueryVariables,
    options?: UseQueryOptions<MyPoolFarmCreateQuery, TError, TData>,
) =>
    useQuery<MyPoolFarmCreateQuery, TError, TData>(
        ['myPoolFarmCreate', variables],
        fetcher<MyPoolFarmCreateQuery, MyPoolFarmCreateQueryVariables>(
            dataSource.endpoint,
            dataSource.fetchParams || {},
            MyPoolFarmCreateDocument,
            variables,
        ),
        options,
    )
export const MyPoolsStakeAvailableDocument = `
    query myPoolsStakeAvailable($address: Bytes!) {
  farmingUsers(
    where: {address: $address, ended: false}
    first: 20
    orderBy: id
    orderDirection: desc
  ) {
    amount
    harvested
    farmingPool {
      id
      pair {
        id
        token0 {
          id
          symbol
          name
          decimals
        }
        token1 {
          id
          symbol
          name
          decimals
        }
      }
      tokenReward {
        id
        name
        symbol
        decimals
      }
      startDate
      endDate
      apr
      totalLP
      amountPerSecond
      isBlueCheck
    }
  }
}
    `
export const useMyPoolsStakeAvailableQuery = <TData = MyPoolsStakeAvailableQuery, TError = unknown>(
    dataSource: { endpoint: string; fetchParams?: RequestInit },
    variables: MyPoolsStakeAvailableQueryVariables,
    options?: UseQueryOptions<MyPoolsStakeAvailableQuery, TError, TData>,
) =>
    useQuery<MyPoolsStakeAvailableQuery, TError, TData>(
        ['myPoolsStakeAvailable', variables],
        fetcher<MyPoolsStakeAvailableQuery, MyPoolsStakeAvailableQueryVariables>(
            dataSource.endpoint,
            dataSource.fetchParams || {},
            MyPoolsStakeAvailableDocument,
            variables,
        ),
        options,
    )
export const FarmingPoolsAvailableDocument = `
    query farmingPoolsAvailable($now: BigDecimal!, $skip: Int) {
  farmingPools(
    first: 10
    skip: $skip
    orderBy: sortIndex
    orderDirection: desc
    where: {startDate_lte: $now, endDate_gt: $now}
  ) {
    id
    pair {
      id
      token0 {
        id
        symbol
        name
        decimals
      }
      token1 {
        id
        symbol
        name
        decimals
      }
    }
    tokenReward {
      id
      name
      symbol
      decimals
    }
    startDate
    endDate
    apr
    totalLP
    amountPerSecond
    amountReward
    harvested
    isBlueCheck
  }
}
    `
export const useFarmingPoolsAvailableQuery = <TData = FarmingPoolsAvailableQuery, TError = unknown>(
    dataSource: { endpoint: string; fetchParams?: RequestInit },
    variables: FarmingPoolsAvailableQueryVariables,
    options?: UseQueryOptions<FarmingPoolsAvailableQuery, TError, TData>,
) =>
    useQuery<FarmingPoolsAvailableQuery, TError, TData>(
        ['farmingPoolsAvailable', variables],
        fetcher<FarmingPoolsAvailableQuery, FarmingPoolsAvailableQueryVariables>(
            dataSource.endpoint,
            dataSource.fetchParams || {},
            FarmingPoolsAvailableDocument,
            variables,
        ),
        options,
    )
export const FarmingPoolDetailDocument = `
    query farmingPoolDetail($poolId: ID!) {
  farmingPool(id: $poolId) {
    id
    pair {
      id
      token0 {
        id
        symbol
        name
        decimals
        derivedUSD
      }
      token1 {
        id
        symbol
        name
        decimals
        derivedUSD
      }
      reserve0
      reserve1
      token0Price
      token1Price
    }
    tokenReward {
      id
      name
      symbol
      decimals
      derivedUSD
    }
    startDate
    endDate
    apr
    totalLP
    amountPerSecond
    lastRewardTimestamp
    accAmountPerShare
    owner
    amountReward
    withdraw
  }
}
    `
export const useFarmingPoolDetailQuery = <TData = FarmingPoolDetailQuery, TError = unknown>(
    dataSource: { endpoint: string; fetchParams?: RequestInit },
    variables: FarmingPoolDetailQueryVariables,
    options?: UseQueryOptions<FarmingPoolDetailQuery, TError, TData>,
) =>
    useQuery<FarmingPoolDetailQuery, TError, TData>(
        ['farmingPoolDetail', variables],
        fetcher<FarmingPoolDetailQuery, FarmingPoolDetailQueryVariables>(
            dataSource.endpoint,
            dataSource.fetchParams || {},
            FarmingPoolDetailDocument,
            variables,
        ),
        options,
    )
export const UserDocument = `
    query user($userId: ID!) {
  farmingUser(id: $userId) {
    amount
    rewardEarn
    rewardDebt
  }
}
    `
export const useUserQuery = <TData = UserQuery, TError = unknown>(
    dataSource: { endpoint: string; fetchParams?: RequestInit },
    variables: UserQueryVariables,
    options?: UseQueryOptions<UserQuery, TError, TData>,
) =>
    useQuery<UserQuery, TError, TData>(
        ['user', variables],
        fetcher<UserQuery, UserQueryVariables>(
            dataSource.endpoint,
            dataSource.fetchParams || {},
            UserDocument,
            variables,
        ),
        options,
    )
export const AvailablePoolsDocument = `
    query availablePools($skip: Int) {
  pairs(first: 10, orderBy: reserveUSD, orderDirection: desc, skip: $skip) {
    id
    reserve0
    reserve1
    reserveUSD
    pairHourData(first: 168, orderBy: hourStartUnix, orderDirection: desc) {
      hourlyVolumeUSD
    }
    currentFee
    token1 {
      id
      name
      decimals
      symbol
    }
    token0 {
      id
      name
      decimals
      symbol
    }
  }
}
    `
export const useAvailablePoolsQuery = <TData = AvailablePoolsQuery, TError = unknown>(
    dataSource: { endpoint: string; fetchParams?: RequestInit },
    variables?: AvailablePoolsQueryVariables,
    options?: UseQueryOptions<AvailablePoolsQuery, TError, TData>,
) =>
    useQuery<AvailablePoolsQuery, TError, TData>(
        variables === undefined ? ['availablePools'] : ['availablePools', variables],
        fetcher<AvailablePoolsQuery, AvailablePoolsQueryVariables>(
            dataSource.endpoint,
            dataSource.fetchParams || {},
            AvailablePoolsDocument,
            variables,
        ),
        options,
    )
export const PairDayDataDocument = `
    query pairDayData($id: ID!) {
  pair(id: $id) {
    id
    token0 {
      id
    }
    token1 {
      id
    }
    pairDayData(first: 20, orderBy: date, orderDirection: asc) {
      date
      reserve0
      reserve1
    }
  }
}
    `
export const usePairDayDataQuery = <TData = PairDayDataQuery, TError = unknown>(
    dataSource: { endpoint: string; fetchParams?: RequestInit },
    variables: PairDayDataQueryVariables,
    options?: UseQueryOptions<PairDayDataQuery, TError, TData>,
) =>
    useQuery<PairDayDataQuery, TError, TData>(
        ['pairDayData', variables],
        fetcher<PairDayDataQuery, PairDayDataQueryVariables>(
            dataSource.endpoint,
            dataSource.fetchParams || {},
            PairDayDataDocument,
            variables,
        ),
        options,
    )
export const AllPairDocument = `
    query allPair($first: Int = 50) {
  pairs(first: $first) {
    id
    token1 {
      id
      name
      decimals
      symbol
    }
    token0 {
      id
      name
      decimals
      symbol
    }
  }
}
    `
export const useAllPairQuery = <TData = AllPairQuery, TError = unknown>(
    dataSource: { endpoint: string; fetchParams?: RequestInit },
    variables?: AllPairQueryVariables,
    options?: UseQueryOptions<AllPairQuery, TError, TData>,
) =>
    useQuery<AllPairQuery, TError, TData>(
        variables === undefined ? ['allPair'] : ['allPair', variables],
        fetcher<AllPairQuery, AllPairQueryVariables>(
            dataSource.endpoint,
            dataSource.fetchParams || {},
            AllPairDocument,
            variables,
        ),
        options,
    )
export const SearchPairDocument = `
    query searchPair($query: String!) {
  pairs(
    where: {or: [{name_contains_nocase: $query}, {token0_: {or: [{symbol_contains_nocase: $query}, {name_contains_nocase: $query}]}}, {token1_: {or: [{symbol_contains_nocase: $query}, {name_contains_nocase: $query}]}}]}
  ) {
    id
    token1 {
      id
      name
      decimals
      symbol
    }
    token0 {
      id
      name
      decimals
      symbol
    }
  }
}
    `
export const useSearchPairQuery = <TData = SearchPairQuery, TError = unknown>(
    dataSource: { endpoint: string; fetchParams?: RequestInit },
    variables: SearchPairQueryVariables,
    options?: UseQueryOptions<SearchPairQuery, TError, TData>,
) =>
    useQuery<SearchPairQuery, TError, TData>(
        ['searchPair', variables],
        fetcher<SearchPairQuery, SearchPairQueryVariables>(
            dataSource.endpoint,
            dataSource.fetchParams || {},
            SearchPairDocument,
            variables,
        ),
        options,
    )
export const GetTvlDocument = `
    query getTVL {
  echodexFactories(first: 1) {
    totalLiquidityUSD
    totalVolumeUSD
    totalTransactions
  }
}
    `
export const useGetTvlQuery = <TData = GetTvlQuery, TError = unknown>(
    dataSource: { endpoint: string; fetchParams?: RequestInit },
    variables?: GetTvlQueryVariables,
    options?: UseQueryOptions<GetTvlQuery, TError, TData>,
) =>
    useQuery<GetTvlQuery, TError, TData>(
        variables === undefined ? ['getTVL'] : ['getTVL', variables],
        fetcher<GetTvlQuery, GetTvlQueryVariables>(
            dataSource.endpoint,
            dataSource.fetchParams || {},
            GetTvlDocument,
            variables,
        ),
        options,
    )
export const GetAirdropDataDocument = `
    query getAirdropData($address: Bytes!, $addressAndDayId: ID!) {
  userLiquidities(where: {address: $address}, first: 500) {
    liquidity
    pair {
      lpTokenPrice
    }
  }
  userSwapDayData(id: $addressAndDayId) {
    swapVolumeUSD
  }
  farmingUsers(first: 500, where: {address: $address}) {
    amount
    farmingPool {
      pair {
        lpTokenPrice
      }
    }
  }
}
    `
export const useGetAirdropDataQuery = <TData = GetAirdropDataQuery, TError = unknown>(
    dataSource: { endpoint: string; fetchParams?: RequestInit },
    variables: GetAirdropDataQueryVariables,
    options?: UseQueryOptions<GetAirdropDataQuery, TError, TData>,
) =>
    useQuery<GetAirdropDataQuery, TError, TData>(
        ['getAirdropData', variables],
        fetcher<GetAirdropDataQuery, GetAirdropDataQueryVariables>(
            dataSource.endpoint,
            dataSource.fetchParams || {},
            GetAirdropDataDocument,
            variables,
        ),
        options,
    )
export const GetPriceDocument = `
    query getPrice($address: ID!) {
  token(id: $address) {
    derivedUSD
  }
}
    `
export const useGetPriceQuery = <TData = GetPriceQuery, TError = unknown>(
    dataSource: { endpoint: string; fetchParams?: RequestInit },
    variables: GetPriceQueryVariables,
    options?: UseQueryOptions<GetPriceQuery, TError, TData>,
) =>
    useQuery<GetPriceQuery, TError, TData>(
        ['getPrice', variables],
        fetcher<GetPriceQuery, GetPriceQueryVariables>(
            dataSource.endpoint,
            dataSource.fetchParams || {},
            GetPriceDocument,
            variables,
        ),
        options,
    )
export const GetTokenDayDataDocument = `
    query getTokenDayData($address: ID!) {
  tokenDayDatas(
    orderBy: date
    orderDirection: asc
    first: 20
    where: {token_: {id: $address}}
  ) {
    date
    priceUSD
  }
}
    `
export const useGetTokenDayDataQuery = <TData = GetTokenDayDataQuery, TError = unknown>(
    dataSource: { endpoint: string; fetchParams?: RequestInit },
    variables: GetTokenDayDataQueryVariables,
    options?: UseQueryOptions<GetTokenDayDataQuery, TError, TData>,
) =>
    useQuery<GetTokenDayDataQuery, TError, TData>(
        ['getTokenDayData', variables],
        fetcher<GetTokenDayDataQuery, GetTokenDayDataQueryVariables>(
            dataSource.endpoint,
            dataSource.fetchParams || {},
            GetTokenDayDataDocument,
            variables,
        ),
        options,
    )
export const CanClaimDocument = `
    query canClaim($address: Bytes!, $date: Int!) {
  xecpRedeems(
    where: {address: $address, endTime_lte: $date, ECP: "0", xECP_gt: "0"}
  ) {
    id
    ECP
    address
    endTime
    index
    startTime
    xECP
    canClaim
  }
}
    `
export const useCanClaimQuery = <TData = CanClaimQuery, TError = unknown>(
    dataSource: { endpoint: string; fetchParams?: RequestInit },
    variables: CanClaimQueryVariables,
    options?: UseQueryOptions<CanClaimQuery, TError, TData>,
) =>
    useQuery<CanClaimQuery, TError, TData>(
        ['canClaim', variables],
        fetcher<CanClaimQuery, CanClaimQueryVariables>(
            dataSource.endpoint,
            dataSource.fetchParams || {},
            CanClaimDocument,
            variables,
        ),
        options,
    )
export const PendingDocument = `
    query pending($address: Bytes!, $date: Int!) {
  xecpRedeems(
    where: {address: $address, endTime_gte: $date, ECP: "0", xECP_gt: "0"}
  ) {
    id
    ECP
    address
    endTime
    index
    startTime
    xECP
    canClaim
  }
}
    `
export const usePendingQuery = <TData = PendingQuery, TError = unknown>(
    dataSource: { endpoint: string; fetchParams?: RequestInit },
    variables: PendingQueryVariables,
    options?: UseQueryOptions<PendingQuery, TError, TData>,
) =>
    useQuery<PendingQuery, TError, TData>(
        ['pending', variables],
        fetcher<PendingQuery, PendingQueryVariables>(
            dataSource.endpoint,
            dataSource.fetchParams || {},
            PendingDocument,
            variables,
        ),
        options,
    )
