import { ChainId, ERC20Token, WETH9 } from '@echodex/sdk';

// src/common.ts
var BUSD = {
  [ChainId.LINEA_TESTNET]: new ERC20Token(
    ChainId.LINEA_TESTNET,
    "0x5471ea8f739dd37E9B81Be9c5c77754D8AA953E4",
    18,
    "BUSD",
    "Binance USD"
  ),
  [ChainId.LINEA_MAINNET]: new ERC20Token(
    ChainId.LINEA_MAINNET,
    "0x7d43AABC515C356145049227CeE54B608342c0ad",
    18,
    "BUSD",
    "Binance USD"
  )
};
var ECP = {
  [ChainId.LINEA_TESTNET]: new ERC20Token(
    ChainId.LINEA_TESTNET,
    "0x72038bbaF749F4b10E525C9E2bB8ae987288a8BE",
    18,
    "ECP",
    "ECP"
  ),
  [ChainId.LINEA_MAINNET]: new ERC20Token(
    ChainId.LINEA_MAINNET,
    "0x9201f3b9DfAB7C13Cd659ac5695D12D605B5F1e6",
    18,
    "ECP",
    "ECP"
  )
};
var xECP = {
  [ChainId.LINEA_TESTNET]: new ERC20Token(
    ChainId.LINEA_TESTNET,
    "0xA76293AD1Dc1F020467e94B330579408b8B7848a",
    18,
    "xECP",
    "xECP"
  ),
  [ChainId.LINEA_MAINNET]: new ERC20Token(
    ChainId.LINEA_MAINNET,
    "0xB7e9eF7713fA256E6d360F9ebcd4D007B107FDea",
    18,
    "xECP",
    "xECP"
  )
};
var USDC = {
  [ChainId.LINEA_TESTNET]: new ERC20Token(
    ChainId.LINEA_TESTNET,
    "0xf56dc6695cF1f5c364eDEbC7Dc7077ac9B586068",
    6,
    "USDC",
    "USD Coin"
  ),
  [ChainId.LINEA_MAINNET]: new ERC20Token(
    ChainId.LINEA_MAINNET,
    "0x176211869cA2b568f2A7D4EE941E073a821EE1ff",
    6,
    "USDC",
    "USDC"
  )
};
var USDT = {
  [ChainId.LINEA_TESTNET]: new ERC20Token(
    ChainId.LINEA_TESTNET,
    "0x4CCb503a5d792eabEFF688010e609d40f9a54148",
    18,
    "USDT",
    "Tether USD"
  ),
  [ChainId.LINEA_MAINNET]: new ERC20Token(
    ChainId.LINEA_MAINNET,
    "0xA219439258ca9da29E9Cc4cE5596924745e12B93",
    6,
    "USDT",
    "Tether USD"
  )
};
var STABLE_COIN = {
  [ChainId.LINEA_TESTNET]: BUSD[ChainId.LINEA_TESTNET],
  [ChainId.LINEA_MAINNET]: USDC[ChainId.LINEA_MAINNET]
};
var EMPTY_LIST = {
  [ChainId.LINEA_TESTNET]: {},
  [ChainId.LINEA_MAINNET]: {}
};
function serializeTokens(unserializedTokens) {
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: unserializedTokens[key].serialize };
  }, {});
  return serializedTokens;
}
var lineaTestnetTokens = {
  weth: WETH9[ChainId.LINEA_TESTNET],
  usdc: USDC[ChainId.LINEA_TESTNET],
  xecp: xECP[ChainId.LINEA_TESTNET],
  ecp: ECP[ChainId.LINEA_TESTNET],
  busd: BUSD[ChainId.LINEA_TESTNET],
  usdt: USDT[ChainId.LINEA_TESTNET]
};
var lineaTokens = {
  weth: WETH9[ChainId.LINEA_MAINNET],
  usdc: USDC[ChainId.LINEA_MAINNET],
  xecp: xECP[ChainId.LINEA_MAINNET],
  ecp: ECP[ChainId.LINEA_MAINNET],
  busd: BUSD[ChainId.LINEA_MAINNET],
  usdt: USDT[ChainId.LINEA_MAINNET]
};

export { BUSD, ECP, EMPTY_LIST, STABLE_COIN, USDC, USDT, lineaTestnetTokens, lineaTokens, serializeTokens, xECP };
